/*
 * @Author: xzj
 * @Date: 2021-05-07 10:11:18
 * @Description: 储存工具函数
 */
import { Midi } from "@tonejs/midi";
const Tone = require("./tone"); // 根据实际文件路径引入

/**
 * 用于解析我们自己特有语法的歌词
 */
class Lyrics {
  constructor(lyrics) {
    // 给类里面的函数绑定this
    this.convertLyrics = this.convertLyrics.bind(this);
    this.convertLyricsSingleLine = this.convertLyricsSingleLine.bind(this);
    this.parseChordString = this.parseChordString.bind(this);

    this.chordId = 1; // 用于记录和弦id
    // 解析歌词数据
    this.lyricObj = this.convertLyrics(lyrics);
  }
  /**
   * 将含有自定义语法的歌词字符串，转化成可json话的歌词数组
   * @param {String} lyrics 从打谱器得来的歌词字符串，含有自定义语法
   * @return {Array} 可json化的歌词数组
   */
  convertLyrics(lyrics) {
    // 将含有语法的歌词进行简化拆分，
    // 以便处理单行的歌词

    lyrics = lyrics.replace(/[\n\f\r\t]/g, "");
    // lyrics = lyrics.replace(/[\n\f\r\t\s]/g, ""); // 清空不必要的空格，换行符等

    if (lyrics == "") {
      // 如果用户传入的是无效字符，直接返回
      return [];
    }

    lyrics = "_" + lyrics;
    lyrics = lyrics
      .replace(/\/$/, "") // 去掉结尾的换行符
      .replace(/_*\/_*/g, "_/_") // 换行符前后添加空格标记
      .replace(/\/_(?=\[\w*?\]_)/g, "/") // 去掉行首错误的下划线，即加载行首空格上的和弦
      .replace(/_/g, " "); // 将空格标记(即下划线)替换成空格

    // 按 / 进行换行拆分
    let lyricList = lyrics.split("/");

    // 转换每一行歌词的数据格式
    lyricList = lyricList.map(this.convertLyricsSingleLine);
    return lyricList;
  }
  /**
   * 将拆分好的单行歌词字符串转换成可json化的数据格式
   * @param {String} lyric 拆分好的单行歌词字符串,
   * 拆分处理后的歌词字符串的格式如下：
   * ' 一[C]闪一闪 亮晶晶 [A7]'
   * @return {Array} 按单个字符构造的歌词数组（单行的）
   */
  convertLyricsSingleLine(lyrics) {
    // 这里的主要思路是将找到的和弦先用*占位，然后在
    // 循环一遍以最终构成需要的数据格式

    let result = [];
    // 先找出匹配的和弦
    let matchResult = lyrics.match(/\[\w*?#?\w*?\]./g);

    // 然后将对应的和弦替换成 特殊字符
    lyrics = lyrics.replace(/\[\w*?#?\w*?\]./g, "∮");
    // 最后将每一个字符都拆分开来，循环构造出需要的数据格式
    lyrics.split("").forEach((item) => {
      let keyObject = {
        word: item,
        mainKey: "",
        attachKey: "",
      };
      if (item == "∮") {
        // 遇到占位符，就将匹配结果的头一个和弦信息填充进去
        keyObject = Object.assign(
          keyObject,
          this.parseChordString(matchResult.shift())
        );

        // 添加和弦的id
        keyObject.chordId = this.chordId;
        this.chordId++;
      }

      result.push(keyObject);
    });

    return result;
  }
  /**
   * 从和弦字符串解析出和弦数据
   * @param {String} key 和弦字符串，类似于 '[C7]曲'
   * @returns 解析出的和弦数据的object对象，内容类似于：
   * {
   *   word: '曲',
   *   mainKey: 'C',
   *   attachKey: '7',
   * }
   */
  parseChordString(key) {
    // 这里的key 如果是输入字符串有【*】的话，会有报错，要重新梳理逻辑...---0909
    let result = {};
    let length = 1;
    let mainKey = "";
    let isUpCase = true;

    for (let index = 0; index < Tone.values.length; index++) {
      const ele = Tone.values[index];
      // console.log("Tones.........."+ele); // 输出: 0
      if (ele.fall) {
        length = ele.fall.length;
        if (key.length - 2 < length) {
          continue;
        }
        const head = key.substring(1, 1 + length);
        if (head == ele.fall) {
          mainKey = ele.fall;
          break;
        } else if (head == ele.fall.toLowerCase()) {
          mainKey = ele.fall;
          isUpCase = false;
          break;
        }
      }
      if (ele.ris) {
        length = ele.ris?.length;
        if (key.length - 2 < length) {
          continue;
        }
        const head = key.substring(1, 1 + length);
        if (head == ele.ris) {
          mainKey = ele.ris;
          break;
        } else if (head == ele.ris.toLowerCase()) {
          mainKey = ele.ris;
          isUpCase = false;
          break;
        }
      }
      continue;
    }

    result.mainKey = mainKey;
    result.word = key[key.length - 1];
    result.attachKey =
      key[2] != "]" ? key.substring(1 + length, key.length - 2) : "";

    return result;
  }
}

export default {
  /**
   * 解析含有特定语法的歌词字符串
   * @param {String} lyricsString 含有特定语法的歌词字符串
   * @returns 解析后的歌词数据，是一个数组
   */
  parseLyrics(lyricsString) {
    let l = new Lyrics(lyricsString);
    return l.lyricObj;
  },
  /**
   * 解析midi文件的函数
   * @param {File} file 需要解析的midi文件的file对象
   * @return {Promise} 解析后返回一个Promise对象，解析结果作为then的参数返回
   */
  parseMidi(file) {
    return new Promise((resolve) => {
      let result = [];

      // 创建filereader类，并定义文件读取成功后的回调函数
      const fr = new FileReader();
      fr.onloadend = () => {
        // console.log("文件读取成功");

        const midi = new Midi(fr.result); // 第三方库解析后的完整midi对象
        const PPQ = midi.header.ppq; // Pulses Per Quarter，计算节拍和时长用
        const notes = midi.tracks[0].notes; // 音符数据

        notes.forEach((item) => {
          // 这里只需要四个变量pitch, beat, duration, volume
          // 具体的转换格式如下
          // pitch = midi
          // beat = ticks / PPQ
          // duration = durationTicks / PPQ
          // volume = velocity * 127 向下取整
          result.push({
            pitch: item.midi,
            beat: item.ticks / PPQ,
            duration: item.durationTicks / PPQ,
            volume: Math.floor(item.velocity * 127),
          });
        });

        // console.log("解析后的结果: ", result);
        resolve([file.name, result]);
      };

      fr.readAsArrayBuffer(file);
    });
  },
};
