<template>
  <div class="manage">
    <div class="manage-header">
      <!-- form搜索区域 -->
      <el-form :inline="true" :model="searchForm">
        <el-form-item>
          <el-input
            placeholder="用户id"
            v-model="searchForm.userId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="用户昵称"
            v-model="searchForm.nickName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="用户手机号"
            v-model="searchForm.mobile"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="设备id"
            v-model="searchForm.deviceId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="第三方openId"
            v-model="searchForm.externalOpenId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="第三方unionId"
            v-model="searchForm.externalUnionId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="common-tabel">
      <!-- <el-table height="90%" stripe="true" :data="tableData" style="width: 100%"> -->
      <el-table height="90%" :data="tableData" style="width: 100%">
        <el-table-column prop="userId" label="用户id"></el-table-column>
        <el-table-column prop="clientId" label="渠道"></el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column
          prop="mobile"
          label="手机"
          :formatter="formatMobile"
        ></el-table-column>
        <el-table-column prop="sex" label="性别">
          <template slot-scope="scope">
            <el-tag :type="getSexStatusTagType(scope.row.sex)">{{
              getSexStatusText(scope.row.sex)
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="ipAddress" label="ip地址"></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          :formatter="formatDate"
        ></el-table-column>
        <!-- <el-table-column prop="updateTime" label="更新时间" :formatter="formatDate"></el-table-column> -->
        <!-- 状态  0草稿   1发布  2下架 -->
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag :type="getStatusTagType(scope.row.status)">{{
              getStatusText(scope.row.status)
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="addr" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="success"
              @click="handleDetail(scope.row)"
              >详细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          layout="prev, pager, next"
          :total="this.total"
          @current-change="handlePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getUser, addUser, editUser, delUser } from "../api";
import OssUpload from "../components/common/OssUpload.vue";

export default {
  components: {
    OssUpload,
  },
  data() {
    return {
      searchForm: {
        userId: "",
        nickName: "",
        mobile: "",
        externalOpenId: "",
        externalUnionId: "",
        deviceId: "",
      },
      tableData: [],
    };
  },
  methods: {
    handleDetail(row) {
      const userId = row.userId;
      this.$router.push(`/userDetail?userId=${userId}`);
      // this.$router.push({ name: "userDetail", params: { userId } });
    },
    formatDate(row, column) {
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);

      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, "0"); // Add zero padding
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding

      let hours = dt.getHours().toString().padStart(2, "0"); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatMobile(row, column) {
      let mobile = row[column.property];

      if (mobile && mobile.length === 11) {
        // 如果手机号长度为11位，则显示前三位和后四位，中间用星号(*)代替
        return mobile.substring(0, 3) + "****" + mobile.substring(7, 11);
      } else {
        // 如果手机号长度不为11位或为空，则返回原始手机号
        return mobile;
      }
    },
    //状态映射
    getStatusText(status) {
      if (status === 1) {
        return "正常";
      } else if (status === 96) {
        return "系统封禁账号";
      } else if (status === 97) {
        return "注销冷静期中";
      } else if (status === 98) {
        return "注销状态";
      } else if (status === 99) {
        return "删除状态";
      }
    },
    //状态映射
    getStatusTagType(status) {
      if (status === 0) {
        return "success";
      } else if (status === 1) {
        return "primary";
      } else if (status === 2) {
        return "warning";
      }
    },

    //状态映射
    getSexStatusTagType(status) {
      if (status === 0) {
        return "success";
      } else if (status === 1) {
        return "primary";
      } else if (status === 2) {
        return "warning";
      }
    },
    getSexStatusText(status) {
      if (status === 0) {
        return "未知";
      } else if (status === 1) {
        return "男";
      } else if (status === 2) {
        return "女";
      }
    },
    // 获取列表的数据
    getList() {
      // 获取的列表的数据
      //获取列表数据   创建对象  并合并两个对象的属性
      this.$_api
        .USER_SEARCH(this.searchForm)
        .then((resp) => {
          //设置页面数据
          this.tableData = resp.data.data;
        })
        .catch();
    },
    //搜索
    search() {
      this.getList();
    },
  },
  mounted() {},
  computed: {
    buttonText() {
      return this.form.id ? "更新" : "新增";
    },
  },
};
</script>
<style lang="less" scoped>
.manage {
  height: 90%;

  .manage-header {
    display: flex;
    justify-content: space-around; //靠两边
    align-items: center; //垂直状态居中
  }

  .common-tabel {
    position: relative;
    height: calc(100% - 62px);

    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
}
</style>
