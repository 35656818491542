<template>
  <!-- 图片视频 -->
  <div>
    <el-upload
      v-if="fileType === 'image' || fileType === 'video'"
      class="avatar-uploader"
      :action="qiniuConfig.uploadUrl"
      :show-file-list="false"
      :http-request="uploadHttp"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      :on-change="handleFileChange"
    >
      <template v-if="fileType === 'image'">
        <img v-if="imageUrl" :src="imageUrl" class="upload-image" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </template>

      <template v-if="fileType === 'video'">
        <el-button size="small" type="primary">点击上传</el-button>
      </template>
      <!-- 下面那行提示的小字 -->
      <div class="el-upload__tip" slot="tip">
        <span v-if="fileType === 'image'"
          >只能上传jpg/jpeg/png文件，且不超过{{ imgSize }}KB</span
        >
        <span v-else-if="fileType === 'video'">只能上传mp4格式的视频</span>
        <span v-else>支持传其他文件</span>
        <span v-if="info">{{ info }}</span>
      </div>
    </el-upload>
    <el-upload
      v-else-if="fileType === 'mid'"
      class="avatar-uploader"
      :action="qiniuConfig.uploadUrl"
      :show-file-list="false"
      :http-request="uploadHttp"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
    >
      <div class="el-upload__tip" slot="tip">
        <span v-if="fileType === 'mid'">只能上传mid格式的文件</span>
        <span v-else>支持传其他文件</span>
      </div>
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>

    <!--文件-->
    <el-upload
      v-else
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-remove="handleRemove"
      :http-request="uploadHttp"
      :limit="1"
      :file-list="fileList"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
import * as qiniu from "qiniu-js";
import ColorThief from "colorthief";
export default {
  name: "Upload",
  props: {
    src: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
    },
    fileType: {
      type: String,
      default: "image",
    },
    code: {
      type: Number,
      default: 103, //默认是传图片的code
    },
    imgSize: {
      type: Number,
      default: 500,
    },
    info: {
      type: String,
      default: "",
    },
    limitWidthHeightRadio: {
      type: Boolean,
      default: false,
    },
    limitWHPercent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      qiniuConfig: {
        // Qiniu configuration parameters
        bucket: process.env.VUE_APP_QINIU_BUCKET,
        uploadUrl: process.env.VUE_APP_QINIU_URL, // This should be the URL where you handle the upload on the server
      },
      fileList: [],
      imageSrc: null,
      palette: [],
    };
  },
  computed: {
    imageUrl: {
      get() {
        return this.src;
      },
      set(val) {
        this.$emit("update:src", val);
      },
    },
    filePrefix() {
      let prefix = this.prefix ? this.prefix : "unknown/";
      return prefix;
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      return new Promise((resolve, reject) => {
        // 首先检查文件类型和大小
        const isValid = this[this.fileType + "Check"](file);
        if (!isValid) {
          return reject(false);
        }

        // 如果不需要检查宽高比，直接通过
        if (!this.limitWidthHeightRadio && !this.limitWHPercent) {
          return resolve(true);
        }

        // 读取图片并验证宽高比
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const ratio = width / height;

            // 检查16:9比例要求
            if (this.limitWidthHeightRadio) {
              if (ratio > 1.6 && ratio < 1.8) {
                if (!this.limitWHPercent) {
                  return resolve(true);
                }
              } else {
                this.$message.error("图片尺寸错误，请上传16:9尺寸");
                return reject(false);
              }
            }

            // 检查1:1~3:1比例要求
            if (this.limitWHPercent) {
              if (ratio >= 1 && ratio < this.limitWHPercent) {
                return resolve(true);
              } else {
                this.$message.error("图片尺寸错误，请上传1:1～40:14尺寸图片");
                return reject(false);
              }
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      });
    },
    //检查图片类型
    imageCheck(file) {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      //   const isLt500K = file.size / 1024 / 500 < 2;
      const overImgLimit = file.size / 1024 > this.imgSize;
      if (!isImage) {
        this.$message.error("上传图片只能是 JPEG/JPG/PNG 格式!");
      } else if (overImgLimit) {
        this.$message.error(`单张图片大小不能超过 ${this.imgSize}KB!`);
      }
      return isImage && !overImgLimit;
    },
    videoCheck(file) {
      const isMp4 = file.type === "video/mp4";
      if (!isMp4) {
        this.$message.error("上传视频只能是 MP4 格式!");
      }
      return isMp4;
    },
    midCheck(file) {
      const isMid = file.type === "audio/mid" || file.type === "audio/midi";
      if (!isMid) {
        this.$message.error("上传文件只能是mid 格式!");
      }
      return isMid;
    },
    uploadHttp({ file }) {
      let data = {
        code: this.code,
        suffix: "png",
      };
      this.$_api
        .QINIU_GET_TOKEN(data)
        .then((res) => {
          let token = res.data.data.upToken;
          let objectName = this.filePrefix + file.name;

          let loading = this.$loading({
            text: "正在上传...",
            spinner: "el-icon-loading",
          });

          const observer = {
            next: (res) => {
              //   debugger;
              console.log("上传进度: ", res.total.percent.toFixed(2));
            },
            error: (err) => {
              //   debugger;
              this.$message.error(`上传出错，请重试！${err}`);
              loading.close();
            },
            complete: (res) => {
              console.log("上传成功回调", res);
              this.imageUrl = this.qiniuConfig.uploadUrl + res.key; // Assuming that the response from your server contains the key
              loading.close();
            },
          };

          const config = {
            useCdnDomain: true, // 使用cdn加速
            region: null, // Qiniu region, e.g., z0 for China
          };

          const putExtra = {
            mimeType: null,
            params: {},
            fname: "",
          };
          //observable控制上传行为
          const observable = qiniu.upload(
            file,
            objectName,
            token,
            putExtra,
            config
          );
          // 上传开始
          const subscription = observable.subscribe(observer);

          // 手动取消上传
          // subscription.unsubscribe();

          // 组件摧毁时 取消上传
          this.$once("hook:beforeDestroy", () => {
            subscription.unsubscribe();
          });
        })
        .catch();
    },
    handleRemove(file, fileList) {
      console.log(`移除图片回调`, file, fileList);
    },
    clearContent() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput.value = ""; // Clear the selected file
      }
    },
    // 文件上传后的操作
    handleFileChange(file) {
      // 封面比例校验通过后 推荐色 才取值
      if (this.limitWidthHeightRadio) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
          this.extractPalette(e.target.result);
        };
        reader.readAsDataURL(file.raw);
      }
    },
    extractPalette(imageSrc) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const colorThief = new ColorThief();
        const palette = colorThief.getPalette(img, 5);
        this.palette = palette;
        function rgbToHex(r, g, b) {
          return (
            "#" +
            [r, g, b]
              .map((x) => {
                const hex = x.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
              })
              .join("")
          );
        }

        function convertArrayToHex(arr) {
          return arr.map((subArray) =>
            rgbToHex(subArray[0], subArray[1], subArray[2])
          );
        }
        const hexArrayResult = convertArrayToHex(this.palette);
        this.$emit("changeColor", hexArrayResult);
      };
      img.src = imageSrc;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.upload-image {
  width: 178px;
  height: 178px;
  display: block;
}

.upload-video {
  width: 400px;
  height: 300px;
}
</style>
