// 定义 JavaScript 类
class Tone {
    // 构造函数
    constructor(value, ris, fall) {
      this.value = value;
      this.ris = ris;
      this.fall = fall;
    }
  
    // 获取 value 属性
    getValue() {
      return this.value;
    }
  
    // 判断是否有升调或降调
    static hasRisOrFall(str) {
      if (!str) {
        return false;
      }
      for (const item of Tone.values) {
        if (item.ris === str || item.fall === str) {
          return true;
        }
      }
      return false;
    }
  }
  
  // 定义枚举项
  Tone.values = [
    new Tone(0, "C"),
    new Tone(1, "#C", "bD"),
    new Tone(2, "D"),
    new Tone(3, "#D", "bE"),
    new Tone(4, "E"),
    new Tone(5, "F"),
    new Tone(6, "#F", "bG"),
    new Tone(7, "G"),
    new Tone(8, "#G", "bA"),
    new Tone(9, "A"),
    new Tone(10, "#A", "bB"),
    new Tone(11, "B"),
  ];
  
  // 导出 JavaScript 类
  module.exports = Tone;
  