<template>
  <div class="common-table-container">
    <div v-if="tableKey && showColumnControl" class="flex mb-10">
      <TableDisplayColumn
        ref="displayControl"
        class="ml-a mr-5"
        :column-list="getColumns"
        :table-key="tableKey"
        :show-hidden-num="showHiddenNum"
        @change="handleColumnChange"
      />
    </div>
    <el-table
      ref="tableRef"
      v-horizontal-scroll="'always'"
      class="common-table"
      :data="tableData"
      :max-height="maxTableHeight"
      v-bind="$attrs"
      :size="size"
      :empty-text="emptyText"
      :show-summary="showSummary"
      v-on="$listeners"
      @selection-change="handleSelectionChange"
    >
      <template v-for="col in column_list">
        <!-- 复选框的列 -->
        <el-table-column
          v-if="col.type === 'selection'"
          :key="col.type"
          type="selection"
          v-bind="col"
        />
        <!-- 扩展列 -->
        <el-table-column
          v-else-if="col.type === 'expand'"
          :key="col.prop"
          type="expand"
          v-bind="col"
        >
          <template slot-scope="scope">
            <slot name="expand" v-bind="scope" />
          </template>
        </el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-else-if="col.type === 'index'"
          :key="col.type + '1'"
          type="index"
          v-bind="col"
        />
        <!-- 非复选框和扩展的列 -->
        <el-table-column
          v-else
          v-show="col.show !== false"
          :key="col.prop + '2'"
          v-bind="col"
        >
          <template slot="header" slot-scope="scope">
            <template v-if="col.headerSlot">
              <slot
                :name="`header_${col.prop}`"
                :col_data="col"
                :scope_data="scope"
              />
            </template>
            <template v-else>
              {{ col.label }}
            </template>
          </template>
          <!-- 包含二级表头 -->
          <template v-if="col.childrens">
            <el-table-column
              v-for="chil_col in col.childrens"
              :key="chil_col.prop"
              v-bind="chil_col"
            >
              <template slot="header" slot-scope="scope">
                <template v-if="chil_col.headerSlot">
                  <slot
                    :name="`header_${chil_col.prop}`"
                    :col_data="chil_col"
                    :scope_data="scope"
                  />
                </template>
                <template v-else>
                  {{ chil_col.label }}
                </template>
              </template>
              <template slot-scope="scope">
                <template v-if="'render' in chil_col">
                  <table-render
                    v-bind="scope"
                    :render="chil_col.render"
                    :column-data="chil_col"
                  />
                </template>
                <template v-else-if="'isSlot' in chil_col">
                  <table-slot v-bind="scope" :column-data="chil_col" />
                </template>
                <template v-else>{{
                  formatter(scope.row, scope.column, scope.row[chil_col.prop])
                }}</template>
              </template>
            </el-table-column>
          </template>
          <template slot-scope="scope">
            <template v-if="'render' in col">
              <table-render
                v-bind="scope"
                :render="col.render"
                :column-data="col"
              />
            </template>
            <template v-else-if="'isSlot' in col">
              <table-slot v-bind="scope" :column-data="col" />
            </template>
            <template v-else>{{
              formatter(scope.row, scope.column, scope.row[col.prop])
            }}</template>
          </template>
        </el-table-column>
      </template>
      <template #empty>
        <slot name="empty" />
      </template>
    </el-table>
  </div>
</template>

<script>
import { debounce, isNull } from "lodash";
import TableRender from "./tableRender.js";
import TableSlot from "./tableSlot.js";
export default {
  name: "CommonTable",
  components: {
    TableRender,
    TableSlot,
  },
  inheritAttrs: false,
  props: {
    showHiddenNum: {
      type: Boolean,
      default: false,
    },
    // 这里需要格外注意，确保全局搜索 tableKey和table-key 看下是否设置的taleKey重复了，重复了会导致两个表格的key冲突。
    tableKey: {
      type: String,
      default: null,
    },
    showColumnControl: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: Function,
      default: (row, column, value) => {
        return value;
      },
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "mini",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    maxHeight: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      column_list: [],
      maxTableHeight: null,
      debounceResize: debounce(this.resizeHandler, 500),
    };
  },
  computed: {
    getColumns() {
      return JSON.parse(JSON.stringify(this.columns));
    },
  },
  provide() {
    return {
      tableRoot: this,
    };
  },
  watch: {
    columns: {
      handler() {
        if (this.showColumnControl && this.tableKey) {
          this.column_list = this.columns;
          this.$nextTick(() => {
            if (this.$refs.displayControl) {
              this.$refs.displayControl.getDefault();
              this.$nextTick(() => {
                this.$refs.displayControl.saveColumn();
              });
            }
            this.$refs.tableRef.doLayout();
          });
        } else {
          this.column_list = this.columns;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.column_list = this.columns;
    if (![null, 0].includes(this.maxHeight)) {
      this.initEvent();
    }
    this.initTableHeight();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debounceResize);
  },
  methods: {
    // 设置显示表格
    handleColumnChange(column_list) {
      this.$set(this, "column_list", column_list);
      this.$refs.tableRef.doLayout();
    },
    initEvent() {
      window.addEventListener("resize", this.debounceResize);
    },
    resizeHandler() {
      this.initTableHeight();
    },
    initTableHeight() {
      let height;
      if (this.maxHeight !== 0) {
        height = this.maxHeight;
      } else {
        height =
          this?.$el?.parentElement?.getBoundingClientRect?.()?.height || 1080;
        const { paddingTop = 0, paddingBottom = 0 } = getComputedStyle(
          this?.$el?.parentElement
        );
        height =
          parseFloat(height) -
          parseFloat(paddingTop) -
          parseFloat(paddingBottom);
      }
      if ((height && !isNaN(height)) || isNull(height)) {
        this.$nextTick(() => {
          this.maxTableHeight = height;
        });
      }
    },
    // 将表格的滚动条滚动某个位置；
    scrollTo(x, y) {
      document
        .querySelector(".common-table .el-table__body-wrapper")
        ?.scrollTo(x, y);
    },
    // 复选框选中的数据
    handleSelectionChange(data) {
      this.$emit("handleSelectionChange", data);
    },
    elTableScrollToRow($table, rowData) {
      const bodyWrapper = $table.$el.querySelector(".el-table__body-wrapper");
      const rowIndex = $table.data.indexOf(rowData);
      const tr = bodyWrapper.querySelectorAll("tr")[rowIndex];

      if (!bodyWrapper || !tr) {
        return;
      }
      if (
        bodyWrapper.clientHeight + bodyWrapper.scrollTop <
          tr.offsetTop + tr.clientHeight ||
        tr.offsetTop < bodyWrapper.scrollTop
      ) {
        bodyWrapper.style.scrollBehavior = "smooth";
        bodyWrapper.scrollTop =
          tr.offsetTop + tr.clientHeight - bodyWrapper.clientHeight;
      }
    },
  },
};
</script>
<style lang="scss">
.common-table-container {
  .el-table {
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          font-size: 14px;
        }
      }
    }
    .el-table__row {
      font-size: 13px;
      color: #39363a;
    }
  }
}
</style>
