var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-container" }, [
    _c(
      "div",
      { staticClass: "l-content" },
      [
        _c("el-button", {
          staticStyle: { "margin-right": "20px" },
          attrs: { icon: "el-icon-menu", size: "mini" },
          on: { click: _vm.handleMenu },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "r-content" },
      [
        _c(
          "el-dropdown",
          { on: { command: _vm.handleClick } },
          [
            _c("span", { staticClass: "el-dropdown-link" }, [
              _c("img", {
                staticClass: "user",
                attrs: { src: require("../assets/images/user.png") },
              }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", [_vm._v("个人中心")]),
                _c("el-dropdown-item", { attrs: { command: "cancel" } }, [
                  _vm._v("退出"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }