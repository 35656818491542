var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("el-aside", { attrs: { width: "auto" } }, [_c("common-aside")], 1),
          _c(
            "el-container",
            [
              _c("el-header", [_c("common-header")], 1),
              _c("common-tag"),
              _c("el-main", [_c("router-view")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }