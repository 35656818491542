<template>
  <el-form
    ref="selfform"
    :model="formdata"
    :size="size"
    :label-width="labelWidth"
    :label-position="labelPosition"
    :inline="inline"
    v-bind="$attrs"
    v-on="$listeners"
    @submit.native.prevent
  >
    <template v-for="(item, index) in form_item">
      <el-form-item
        v-if="!item.hidden"
        :key="index"
        :label-width="item.label_width"
        :label="item.label"
        :rules="item.rules"
        :prop="item.key"
        :class="item.class || ''"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <!-- 自定义表单label -->
        <template #label>
          <span
            v-if="item.custom_item_label"
            slot="label"
            @click="handleLabelClick(item)"
          >
            {{ item.label }}
            <span v-html="item.labelHtml" />
          </span>
          <slot v-else :name="`label_${item.key}`" v-bind="item" />
        </template>
        <el-autocomplete
          v-if="item.item_type === 'autocomplete'"
          v-model="formdata[item.key]"
          :disabled="item.disable"
          :fetch-suggestions="item.suggestions"
          :popper-class="item.popper_class"
          :placeholder="item.placeholder"
          :clearable="item.clearable"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
          @select="
            (value) => {
              item.handleSelect(value, {
                item,
                formdata,
                formItemList: form_item,
              });
            }
          "
        />
        <!--输入框-->
        <el-input
          v-else-if="item.item_type === 'input'"
          v-model="formdata[item.key]"
          :disabled="item.disable"
          :show-password="item.showPassword"
          :type="item.type"
          :clearable="item.clearable"
          :show-word-limit="item.show_word_limit"
          :maxlength="item.maxlength"
          :rows="item.rows"
          :resize="item.resize"
          :placeholder="item.placeholder"
          :style="{ width: item.width }"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
          @blur="
            (val) => {
              item.handleBlur &&
                item.handleBlur(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
          @keyup.enter.native="
            (val) => {
              val &&
                item.handleKeyup &&
                item.handleKeyup(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
              val && handleSubmit();
            }
          "
        >
          <div v-if="item.slot" :slot="item.slot">
            {{ item.slot_content }}
          </div>
        </el-input>
        <!--一般文本-->
        <template v-else-if="item.item_type === 'text'">
          {{ item.key === undefined ? item.content : formdata[item.key] }}
        </template>
        <!--选择框-->
        <el-select
          v-else-if="item.item_type === 'select'"
          v-model="formdata[item.key]"
          :style="{ width: item.width }"
          :class="item.class"
          :loading="item.loading"
          :loading-text="item.loading_text"
          :collapse-tags="item.collapse_tags"
          :popper-class="item.popper_class"
          :disabled="item.disable"
          :filterable="item.filterable"
          :filter-method="item.filterMethod"
          :allow-create="item.allow_create"
          :value-key="item.value_key"
          :remote="item.remote"
          :multiple="item.multiple"
          :multiple-limit="item.multiple_limit"
          :clearable="item.clearable"
          :remote-method="
            (queryString) => {
              item.remoteMethod(queryString, {
                item,
                formdata,
                formItemList: form_item,
              });
            }
          "
          :placeholder="item.placeholder"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
          @focus="
            () => {
              if (item.remote) {
                item.remoteMethod('', {
                  item,
                  formdata,
                  formItemList: form_item,
                });
              }
            }
          "
        >
          <el-option
            v-for="(option, idx) in item.options"
            :key="
              item.value_key && option.value
                ? option.value[item.value_key]
                : option.value
                ? `${item.key}_${option.value}_${idx}`
                : typeof option === 'object'
                ? idx
                : option
            "
            :label="option.label !== undefined ? option.label : option"
            :value="option.value !== undefined ? option.value : option"
            :title="option.label !== undefined ? option.label : option"
            :disabled="option.disabled || false"
          >
            <div
              :class="
                item.line_clamp ? 'line-clamp-2 submit-selecet-option' : ''
              "
            >
              {{ option.label !== undefined ? option.label : option }}
            </div>
          </el-option>
        </el-select>
        <!--带排序的穿梭框-->
        <TransferWithSort
          v-else-if="item.item_type === 'transfer_with_sort'"
          :can-sort="item.can_sort"
          :left-title="item.left_title"
          :right-title="item.right_title"
          :left-data="formdata[item.left_key]"
          :right-data="formdata[item.right_key]"
        />
        <!--单选项组-->
        <el-radio-group
          v-else-if="item.item_type === 'radio_group'"
          v-model="formdata[item.key]"
          :class="item.class"
          :disabled="item.disable"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        >
          <el-radio
            v-for="(children, idx) in item.childrens"
            :key="idx"
            :label="children.value"
            :disabled="children.disable"
          >
            {{ children.text ? children.text : children.value }}
          </el-radio>
        </el-radio-group>
        <!--可选项-->
        <el-checkbox
          v-else-if="item.item_type === 'check_box'"
          v-model="formdata[item.key]"
          :disabled="item.disable"
          :false-label="item.false_value"
          :true-label="item.true_value"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        >
          {{ item.text ? item.text : item.true_value }}
        </el-checkbox>
        <!--可选项组-->
        <el-checkbox-group
          v-else-if="item.item_type === 'check_box_group'"
          v-model="formdata[item.key]"
          :disabled="item.disable"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        >
          <el-checkbox
            v-for="(child, idx) in item.childrens"
            :key="idx"
            :label="child.value"
            >{{ child.text }}</el-checkbox
          >
        </el-checkbox-group>
        <!--联级选择器-->
        <el-cascader
          v-else-if="item.item_type === 'cascader'"
          v-model="formdata[item.key]"
          :popper-class="item.popper_class"
          :disabled="item.disable"
          :props="item.props"
          :collapse-tags="item.collapse_tags"
          :options="item.options"
          :clearable="item.clearable"
          :emit-path="item.emitPath"
          :show-all-levels="item.show_all_levels"
          :placeholder="item.placeholder"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        />
        <!--日期选择器-->
        <el-date-picker
          v-else-if="item.item_type === 'date-picker'"
          v-model="formdata[item.key]"
          :popper-class="item.popper_class"
          :disabled="item.disable"
          :type="item.type"
          :format="item.format"
          :value-format="item.value_format"
          :start-placeholder="item.start_placeholder"
          :end-placeholder="item.end_placeholder"
          :placeholder="item.placeholder"
          :picker-options="
            getPickOption({ item, formdata, formItemList: form_item })
          "
          :default-time="item.defaultTime"
          :clearable="item.clearable"
          :style="{ width: item.width }"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        />
        <!--时间选择器-->
        <el-time-picker
          v-else-if="item.item_type === 'time-picker'"
          v-model="formdata[item.key]"
          :disabled="item.disable"
          :value-format="item.value_format"
          :is-range="item.is_range"
          :default-value="formdata[item.key]"
          :start-placeholder="item.start_placeholder"
          :end-placeholder="item.end_placeholder"
          :placeholder="item.placeholder"
          :picker-options="item.pickerOptions"
          :clearable="item.clearable"
          @change="
            (val) => {
              item.handleChange &&
                item.handleChange(val, {
                  item,
                  formdata,
                  formItemList: form_item,
                });
            }
          "
        />
        <!--自定义表单-->
        <template v-else-if="item.item_type === 'custom-item' && item.slot">
          <slot :name="item.slot" />
        </template>

        <!--自定义表单内容带作用域-->
        <template v-else-if="item.item_type === 'custom-item-scope'">
          <slot :name="item.slot" :slotProps="item" />
        </template>
        <!-- 通用组件 适用整体项目内可用组件，option设置组件大部分属性，listeners设置组件事件-->
        <component
          :is="item.item_type"
          v-else
          v-model="formdata[item.key]"
          v-bind="item.option"
          v-on="item.listeners"
        >
          <slot v-if="item.slot" :name="item.slot" />
        </component>
      </el-form-item>
    </template>
    <!-- 自定义按钮 -->
    <el-form-item v-if="$slots.buttons">
      <slot name="buttons" />
    </el-form-item>
    <!-- 默认按钮 -->
    <el-form-item
      v-else-if="!hiddenButton"
      :class="inline ? '' : 'text-center'"
    >
      <el-button
        v-track="trackInfo.submit"
        type="primary"
        :icon="subtnIcon"
        @click="handleSubmit"
        >{{ subtnText }}</el-button
      >
      <el-button
        v-if="cancle"
        v-track="trackInfo.cancle"
        type="primary"
        @click="handleCancel"
        >{{ cancelText }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import TransferWithSort from "./components/TransferWithSort.vue";
export default {
  name: "SubmitForm",
  components: {
    TransferWithSort,
  },
  props: {
    hiddenButton: {
      type: Boolean,
      default: false,
    },
    cancle: {
      type: Boolean,
      default: false,
    },
    trackInfo: {
      type: Object,
      default: () => {
        return {
          cancle: null,
          submit: null,
        };
      },
    },
    subtnText: {
      type: String,
      default: "提交",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: undefined,
    },
    subtnIcon: {
      type: String,
      default: "",
    },
    dataValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    formItemList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    labelPosition: {
      type: String,
      default: "right",
    },
  },

  data() {
    return {
      formdata: {},
      form_item: [],
      temp_buffer: {},
    };
  },
  watch: {
    formItemList: {
      handler(val) {
        this.form_item = this.formItemList.map((item) => {
          const res = { ...item };
          const temp = { ...item.listeners };
          Object.keys(temp).forEach((listen) => {
            // 监听方法补充组件列表参数
            res.listeners[listen] = (...args) => {
              temp[listen](
                { item, formItemList: this.form_item, formdata: this.formdata },
                ...args
              );
            };
          });
          return res;
        });
      },
      deep: true,
    },
    formdata: {
      handler(val) {
        this.$refs.selfform.clearValidate();
        this.$emit("update:dataValue", val);
      },
      deep: true,
    },
    dataValue: {
      handler(val) {
        this.formdata = val;
      },
      deep: true,
    },
  },

  created() {
    this.formdata = this.dataValue;
    this.form_item = this.formItemList;
  },
  methods: {
    getPickOption({ item, formdata, formItemList }) {
      if (this.temp_buffer[`${item.key}_pickoption`]) {
        return this.temp_buffer[`${item.key}_pickoption`];
      } else {
        this.temp_buffer[`${item.key}_pickoption`] =
          typeof item.pickerOptions === "function"
            ? item.pickerOptions({
                item,
                formdata,
                formItemList,
              })
            : item.pickerOptions;
      }
    },
    async checkValue() {
      try {
        await this.$refs.selfform.validate();
        return true;
      } catch {
        return false;
      }
    },
    async handleSubmit() {
      this.$emit("handleSubmit", this.formdata, this.trackInfo?.submit?.key);
    },
    handleLabelClick(item) {
      this.$emit("label-click", item);
    },
    handleCancel() {
      this.$emit("handleCancel", this.trackInfo?.cancle?.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select,
.el-select--small,
.el-cascader,
.el-autocomplete,
.el-select--small,
.el-date-picker {
  width: 100%;
}
.submit-selecet-option {
  max-width: 300px;
  font-size: 13px;
  line-height: 17px;
  white-space: break-spaces;
}
</style>
