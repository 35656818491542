/**
 * 注册全局函数，
 * 全部以 $_ 开头，可以全局调用
 */

import func from "./func";
import api from "../api/api.js";
import choice from "../assets/choices.json";

export default {
  install(Vue) {
    Vue.prototype.$_api = api; // 后台接口
    Vue.prototype.$_func = func; // 一些工具函数
    Vue.prototype.$_options = choice; // 一些常用的表单中的选择器的选项
  },
};
