<template>
  <div>
    <el-link :key="dialog_visiable" type="primary" @click="openDialog">
      <i class="el-icon-s-tools" /> 显示字段{{ getHidenCount() }}</el-link
    >
    <el-dialog
      v-if="dialog_visiable"
      :visible.sync="dialog_visiable"
      :close-on-click-modal="false"
      width="800px"
      top="5vh"
      title="显示字段设置"
    >
      <el-checkbox
        v-if="dialog_visiable"
        v-model="select_all"
        :indeterminate="
          edit_rows.length !== getAlltableColumn.length &&
          edit_rows.length !== 0
        "
        @change="selectAllDisplay"
      >
        全选
      </el-checkbox>
      <el-checkbox-group v-model="edit_rows">
        <template v-for="item in getAlltableColumn">
          <template v-if="item.group">
            <el-card
              :key="item.prop"
              shadow="never"
              :header="item.group"
              class="mt-10 table-column-display-card"
            >
              <div v-for="i in item.columns_list" :key="i.prop + 'e'">
                <el-checkbox :label="i.prop" :disabled="i.require_column">
                  <div style="width: 120px">{{ i.label }}</div>
                </el-checkbox>
              </div>
            </el-card>
          </template>
          <template v-else-if="item.childrens && item.childrens.length">
            <el-card
              :key="item.prop"
              shadow="never"
              :header="item.label"
              class="mt-10 table-column-display-card"
            >
              <div v-for="i in item.childrens" :key="i.prop + 'i'">
                <el-checkbox :label="i.prop" :disabled="i.require_column">
                  <div style="width: 120px">{{ i.label }}</div>
                </el-checkbox>
              </div>
            </el-card>
          </template>
          <el-checkbox
            v-else
            :key="item.prop"
            :label="item.prop"
            :disabled="item.require_column"
          >
            <div style="width: 120px">{{ item.label }}</div>
          </el-checkbox>
        </template>
      </el-checkbox-group>
      <template slot="footer">
        <el-button size="mini" @click="dialog_visiable = false">取消</el-button>
        <el-button size="mini" type="primary" @click="saveClick"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "TableDisplayColumn",
  props: {
    showHiddenNum: {
      type: Boolean,
      default: false,
    },
    columnList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    remote: {
      type: Boolean,
      default: false,
    },
    // 这里需要格外注意，确保全局搜索 tableKey和table-key 看下是否设置的taleKey重复了，重复了会导致两个表格的key冲突。
    tableKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dialog_visiable: false,
      select_all: false,
      edit_rows: [],
    };
  },
  computed: {
    getAllCheckboxTableColumn() {
      return this.columnList.filter(
        (item) =>
          !["selection", "index", "expand"].includes(item.type) &&
          !item.never_display
      );
    },
    getAlltableColumn() {
      return this.getAllCheckboxTableColumn
        .reduce((prev, cur) => {
          if ((cur.display_group ?? "") !== "") {
            const temp = prev.find((i) => i.group === cur.display_group);
            if (temp) {
              (temp.columns_list || (temp.columns_list = [])).push(cur);
            } else {
              prev.push({
                group: cur.display_group,
                columns_list: [cur],
              });
            }
          } else {
            prev.push(cur);
          }
          return prev;
        }, [])
        .sort((prev, cur) => {
          if ((prev.group ?? "") === "" && (cur.group ?? "") === "") {
            return 0;
          } else if ((prev.group ?? "") !== "") {
            return 1;
          } else {
            return -1;
          }
        });
    },
    getAlltableColumnLen() {
      return this.getAlltableColumn.reduce((prev, cur) => {
        const length = cur?.columns_list?.length || cur?.childrens?.length || 1;
        return prev + length;
      }, 0);
    },
  },
  watch: {
    edit_rows(val) {
      if (val.length === this.getAlltableColumnLen) {
        this.select_all = true;
      } else if (val.length === 0) {
        this.select_all = false;
      }
    },
    dialog_visiable(val) {
      if (!val) {
        this.getDefault();
      }
    },
  },
  async mounted() {
    // 进入页面时读取上次配置值
    await this.getDefault();
    this.saveColumn();
  },
  methods: {
    // 显示已隐藏信息
    getHidenCount() {
      return this.showHiddenNum
        ? `(已隐藏${this.getAlltableColumnLen - this.edit_rows.length}列)`
        : "";
    },
    // 打开配置窗口
    openDialog() {
      this.dialog_visiable = true;
      this.getDefault();
    },
    // 全选
    selectAllDisplay() {
      if (this.select_all) {
        this.edit_rows = [];
        this.getAllCheckboxTableColumn.forEach((item) => {
          if (item.childrens && item.childrens.length) {
            this.edit_rows.push(...item.childrens.map((child) => child.prop));
          } else {
            this.edit_rows.push(item.prop);
          }
        });
      } else {
        this.edit_rows = [];
        this.getAllCheckboxTableColumn.forEach((item) => {
          if (item.childrens && item.childrens.length) {
            this.edit_rows.push(
              ...item.childrens
                .filter((child) => child.require_column)
                .map((child) => child.prop)
            );
          } else if (item.require_column) {
            this.edit_rows.push(item.prop);
          }
        });
        this.edit_rows = this.getAllCheckboxTableColumn
          .filter((item) => item.require_column)
          .map((item) => {
            return item.prop;
          });
      }
    },
    // 获取默认值
    async getDefault() {
      this.loading = true;

      if (this.remote) {
        console.log("暂未配置远程存储");
      } else {
        const res = localStorage.getItem(`table_row_${this.tableKey}`);

        if (res) {
          try {
            const temp = [];
            this.getAllCheckboxTableColumn.forEach((item) => {
              if (item.childrens && item.childrens.length) {
                temp.push(...item.childrens.map((child) => child.prop));
              } else {
                temp.push(item.prop);
              }
            });
            this.edit_rows = JSON.parse(res).filter((item) =>
              temp.includes(item)
            );
          } catch {
            this.edit_rows = [];
            this.getAllCheckboxTableColumn.forEach((item) => {
              if (item.childrens && item.childrens.length) {
                this.edit_rows.push(
                  ...item.childrens.map((child) => child.prop)
                );
              } else {
                this.edit_rows.push(item.prop);
              }
            });
          }
        } else {
          this.edit_rows = [];
          this.getAllCheckboxTableColumn
            .filter((item) => !item.default_unchecked)
            .forEach((item) => {
              if (item.childrens && item.childrens.length) {
                this.edit_rows.push(
                  ...item.childrens
                    .filter((item) => !item.default_unchecked)
                    .map((child) => child.prop)
                );
              } else {
                this.edit_rows.push(item.prop);
              }
            });
        }
      }
      this.loading = false;
    },
    async saveDefault() {
      if (this.remote) {
        console.log("暂未配置远程存储");
      } else {
        localStorage.setItem(
          `table_row_${this.tableKey}`,
          JSON.stringify(this.edit_rows)
        );
      }
    },
    saveClick() {
      this.saveDefault();
      this.saveColumn();
    },
    // 保存展示列表
    saveColumn() {
      const temp = [];
      this.columnList.forEach((item) => {
        const temp_item = JSON.parse(JSON.stringify(item));
        if (temp_item.childrens && temp_item.childrens.length) {
          temp_item.childrens = temp_item.childrens.filter(
            (child) =>
              this.edit_rows.includes(child.prop) ||
              ["selection", "index", "expand"].includes(child.type) ||
              child.never_display
          );
          temp_item.childrens.length && temp.push(temp_item);
        } else if (
          this.edit_rows.includes(temp_item.prop) ||
          ["selection", "index", "expand"].includes(temp_item.type) ||
          temp_item.never_display
        ) {
          temp.push(temp_item);
        }
      });
      this.$emit("change", temp);
      this.dialog_visiable = false;
    },
  },
};
</script>
<style lang="scss">
.table-column-display-card {
  .el-card__header,
  .el-card__body {
    padding: 10px;
  }
}
</style>
