var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.fileType === "image" || _vm.fileType === "video"
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: _vm.qiniuConfig.uploadUrl,
                "show-file-list": false,
                "http-request": _vm.uploadHttp,
                "before-upload": _vm.beforeAvatarUpload,
                "on-remove": _vm.handleRemove,
                "on-change": _vm.handleFileChange,
              },
            },
            [
              _vm.fileType === "image"
                ? [
                    _vm.imageUrl
                      ? _c("img", {
                          staticClass: "upload-image",
                          attrs: { src: _vm.imageUrl },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                : _vm._e(),
              _vm.fileType === "video"
                ? [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("点击上传")]
                    ),
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm.fileType === "image"
                    ? _c("span", [
                        _vm._v(
                          "只能上传jpg/jpeg/png文件，且不超过" +
                            _vm._s(_vm.imgSize) +
                            "KB"
                        ),
                      ])
                    : _vm.fileType === "video"
                    ? _c("span", [_vm._v("只能上传mp4格式的视频")])
                    : _c("span", [_vm._v("支持传其他文件")]),
                  _vm.info ? _c("span", [_vm._v(_vm._s(_vm.info))]) : _vm._e(),
                ]
              ),
            ],
            2
          )
        : _vm.fileType === "mid"
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: _vm.qiniuConfig.uploadUrl,
                "show-file-list": false,
                "http-request": _vm.uploadHttp,
                "before-upload": _vm.beforeAvatarUpload,
                "on-remove": _vm.handleRemove,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm.fileType === "mid"
                    ? _c("span", [_vm._v("只能上传mid格式的文件")])
                    : _c("span", [_vm._v("支持传其他文件")]),
                ]
              ),
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
            ],
            1
          )
        : _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              attrs: {
                action: "https://jsonplaceholder.typicode.com/posts/",
                "on-remove": _vm.handleRemove,
                "http-request": _vm.uploadHttp,
                limit: 1,
                "file-list": _vm.fileList,
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }