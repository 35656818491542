<template>
  <el-row>
    <el-col :span="24" style="padding-left: 10px">
      <el-card style="margin-top: 15px">
        <el-col :span="16">
          <el-row>
            <i :class="['el-icon-data-line', 'icon-large']"></i>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div>
                <el-statistic title="用户总数">
                  <template slot="formatter">
                    {{ formattedUserCount }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="设备总数">
                  <template slot="formatter">
                    {{ formattedDeviceCount }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="官方曲谱总数">
                  <template slot="formatter">
                    {{ formattedSysSongCount }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="自定义曲谱总数">
                  <template slot="formatter">
                    <span v-html="formattedCusSongCount"></span>
                  </template>
                </el-statistic>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="6">
              <div>
                <el-statistic title="自定义曲谱开放">
                  <template slot="formatter">
                    <span v-html="formattedCusSongCanViewCount"></span>
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="通用配置">
                  <template slot="formatter">
                    {{ formattedComonSettingCount }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="曲谱专属配置">
                  <template slot="formatter">
                    {{ formattedSongSettingCount }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic
                  group-separator=","
                  :precision="2"
                  :value="this.statistics1.averageSongCountOfSheet"
                  title="歌单平均曲谱数"
                ></el-statistic>
              </div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="6">
              <div>
                <el-statistic title="昨日弹唱">
                  <template slot="formatter">
                    {{ formattedPlayAdd }}
                  </template>
                </el-statistic>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="8">
          <div ref="pie1" style="flex: 1; height: 180px"></div>
          <div ref="pie2" style="flex: 1; height: 180px"></div>
        </el-col>
      </el-card>
      <!-- 设备增量 -->
      <el-card style="margin-top: 15px; height: 380px">
        <!-- 折线图 -->
        <div ref="echarts2" style="height: 300px"></div>
        <el-form :inline="true" :model="searchStatistics2">
          <el-form-item>
            <el-select
              v-model="searchStatistics2.timeLevel"
              placeholder="时间类型"
              @change="handleSearchStatistics2"
            >
              <el-option
                v-for="item in $_options['timeLevel_1']"
                :key="item[1]"
                :label="item[0]"
                :value="item[1]"
              ></el-option>
            </el-select>
            <el-select
              v-model="searchStatistics2.year"
              placeholder="年份"
              @change="handleSearchStatistics2"
            >
              <el-option
                v-for="year in statistics2AvailableYears"
                :key="year"
                :label="year"
                :value="year"
              ></el-option>
            </el-select>
            <el-select
              v-if="searchStatistics2.timeLevel === 'day'"
              v-model="searchStatistics2.month"
              placeholder="月份"
              @change="handleSearchStatistics2"
            >
              <el-option
                v-for="month in statistics2AvailableMonths"
                :key="month"
                :label="month"
                :value="month"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- 日活 -->
      <el-card style="margin-top: 15px; height: 380px">
        <!-- 折线图 -->
        <div ref="echarts3" style="height: 300px"></div>
        <el-form :inline="true" :model="searchStatistics3">
          <el-form-item>
            <el-select
              v-model="searchStatistics3.timeLevel"
              placeholder="时间类型"
              @change="handleSearchStatistics3"
            >
              <el-option
                v-for="item in $_options['timeLevel_2']"
                :key="item[1]"
                :label="item[0]"
                :value="item[1]"
              ></el-option>
            </el-select>
            <el-select
              v-model="searchStatistics3.year"
              placeholder="年份"
              @change="handleSearchStatistics3"
            >
              <el-option
                v-for="year in statistics3AvailableYears"
                :key="year"
                :label="year"
                :value="year"
              ></el-option>
            </el-select>
            <el-select
              v-if="searchStatistics3.timeLevel === 'day'"
              v-model="searchStatistics3.month"
              placeholder="月份"
              @change="handleSearchStatistics3"
            >
              <el-option
                v-for="month in statistics3AvailableMonths"
                :key="month"
                :label="month"
                :value="month"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-card>

      <el-col :span="12" style="margin-top: 20px; padding-right: 10px">
        <el-card style="height: 380px">
          <!-- 折线图 -->
          <div ref="echarts4" style="height: 300px"></div>
          <el-form :inline="true" :model="searchStatistics4">
            <el-form-item>
              <el-select
                v-model="searchStatistics4.timeLevel"
                placeholder="时间类型"
                @change="handleSearchStatistics4"
              >
                <el-option
                  v-for="item in $_options['timeLevel_1']"
                  :key="item[1]"
                  :label="item[0]"
                  :value="item[1]"
                ></el-option>
              </el-select>
              <el-select
                v-model="searchStatistics4.year"
                placeholder="年份"
                @change="handleSearchStatistics4"
              >
                <el-option
                  v-for="year in statistics4AvailableYears"
                  :key="year"
                  :label="year"
                  :value="year"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics4.timeLevel === 'day'"
                v-model="searchStatistics4.month"
                placeholder="月份"
                @change="handleSearchStatistics4"
              >
                <el-option
                  v-for="month in statistics4AvailableMonths"
                  :key="month"
                  :label="month"
                  :value="month"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card style="margin-top: 20px; height: 380px">
          <!-- 折线图 -->
          <div ref="echarts6" style="height: 300px"></div>
          <el-form :inline="true" :model="searchStatistics6">
            <el-form-item>
              <el-select
                v-model="searchStatistics6.timeLevel"
                placeholder="时间类型"
                @change="handleSearchStatistics6"
              >
                <el-option
                  v-for="item in $_options['timeLevel_1']"
                  :key="item[1]"
                  :label="item[0]"
                  :value="item[1]"
                ></el-option>
              </el-select>
              <el-select
                v-model="searchStatistics6.year"
                placeholder="年份"
                @change="handleSearchStatistics6"
              >
                <el-option
                  v-for="year in statistics6AvailableYears"
                  :key="year"
                  :label="year"
                  :value="year"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics6.timeLevel === 'day'"
                v-model="searchStatistics6.month"
                placeholder="月份"
                @change="handleSearchStatistics6"
              >
                <el-option
                  v-for="month in statistics6AvailableMonths"
                  :key="month"
                  :label="month"
                  :value="month"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card style="margin-top: 20px; height: 380px">
          <!-- 折线图 -->
          <div ref="echarts8" style="height: 300px"></div>
          <el-form :inline="true" :model="searchStatistics8">
            <el-form-item>
              <el-select
                v-model="searchStatistics8.timeLevel"
                placeholder="时间类型"
                @change="handleSearchStatistics8"
              >
                <el-option
                  v-for="item in $_options['timeLevel_1']"
                  :key="item[1]"
                  :label="item[0]"
                  :value="item[1]"
                ></el-option>
              </el-select>
              <el-select
                v-model="searchStatistics8.year"
                placeholder="年份"
                @change="handleSearchStatistics8"
              >
                <el-option
                  v-for="year in statistics8AvailableYears"
                  :key="year"
                  :label="year"
                  :value="year"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics8.timeLevel === 'day'"
                v-model="searchStatistics8.month"
                placeholder="月份"
                @change="handleSearchStatistics8"
              >
                <el-option
                  v-for="month in statistics8AvailableMonths"
                  :key="month"
                  :label="month"
                  :value="month"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>

      <el-col :span="12" style="margin-top: 20px; padding-right: 10px">
        <el-card style="height: 380px">
          <!-- 弹唱 -->
          <div ref="echarts5" style="height: 300px"></div>
          <el-form :inline="true" :model="searchStatistics5">
            <el-form-item>
              <el-select
                v-model="searchStatistics5.timeLevel"
                placeholder="时间类型"
                @change="handleSearchStatistics5"
              >
                <el-option
                  v-for="item in $_options['timeLevel_3']"
                  :key="item[1]"
                  :label="item[0]"
                  :value="item[1]"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics5.timeLevel !== 'hour'"
                v-model="searchStatistics5.year"
                placeholder="年份"
                @change="handleSearchStatistics5"
              >
                <el-option
                  v-for="year in statistics5AvailableYears"
                  :key="year"
                  :label="year"
                  :value="year"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics5.timeLevel === 'day'"
                v-model="searchStatistics5.month"
                placeholder="月份"
                @change="handleSearchStatistics5"
              >
                <el-option
                  v-for="month in statistics5AvailableMonths"
                  :key="month"
                  :label="month"
                  :value="month"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="searchStatistics5.timeLevel === 'hour'"
              prop="effectTime"
            >
              <el-date-picker
                v-model="searchStatistics5.hourDate"
                type="date"
                placeholder="选择日期时间"
                :picker-options="statistics5PickerOptions"
                @change="handleSearchStatistics5"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </el-card>
        <el-card style="margin-top: 20px; height: 380px">
          <!-- 自定义曲谱增量 -->
          <div ref="echarts7" style="height: 300px"></div>
          <el-form :inline="true" :model="searchStatistics7">
            <el-form-item>
              <el-select
                v-model="searchStatistics7.timeLevel"
                placeholder="时间类型"
                @change="handleSearchStatistics7"
              >
                <el-option
                  v-for="item in $_options['timeLevel_1']"
                  :key="item[1]"
                  :label="item[0]"
                  :value="item[1]"
                ></el-option>
              </el-select>
              <el-select
                v-model="searchStatistics7.year"
                placeholder="年份"
                @change="handleSearchStatistics7"
              >
                <el-option
                  v-for="year in statistics7AvailableYears"
                  :key="year"
                  :label="year"
                  :value="year"
                ></el-option>
              </el-select>
              <el-select
                v-if="searchStatistics7.timeLevel === 'day'"
                v-model="searchStatistics7.month"
                placeholder="月份"
                @change="handleSearchStatistics7"
              >
                <el-option
                  v-for="month in statistics7AvailableMonths"
                  :key="month"
                  :label="month"
                  :value="month"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import { el } from "date-fns/locale";
import * as echarts from "echarts";

export default {
  data() {
    const currentDate = new Date();
    const isFirstDayOfMonth = currentDate.getDate() === 1;

    return {
      //总览
      statistics1: {
        totalCount: null,
        addCount: null,
        deviceCountStr: null,
        deviceCount: null,
        deviceAddCount: null,
        songCount: null,
        songAddCount: null,
        customSongCount: null,
        customSongAddCount: null,
        commonSettingCount: null,
        commonSettingUserCount: null,
        songSettingCount: null,
        songSettingUserCount: null,
        averageSongCountOfSheet: null,
        playAddCount: null,
        playUserRangeCount: null,
        iosCount: null,
        androidCount: null,
      },
      pie2: {},

      //设备增量
      statistics2EarliestDate: null,
      searchStatistics2: {
        timeLevel: "day",
        month: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? 12
            : currentDate.getMonth()
          : currentDate.getMonth() + 1,
        year: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear()
          : currentDate.getFullYear(),
      },
      statistics2: [],

      //用户增量
      statistics3EarliestDate: null,
      searchStatistics3: {
        timeLevel: "day",
        month: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? 12
            : currentDate.getMonth()
          : currentDate.getMonth() + 1,
        year: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear()
          : currentDate.getFullYear(),
      },
      statistics3: [],
      statistics4EarliestDate: null,
      searchStatistics4: {
        timeLevel: "day",
        month: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? 12
            : currentDate.getMonth()
          : currentDate.getMonth() + 1,
        year: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear()
          : currentDate.getFullYear(),
      },
      statistics4: [],

      //弹唱
      statistics5EarliestDate: null,
      statistics5EarliestPlayHourDate: null,
      searchStatistics5: {
        timeLevel: "hour",
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        hourDate: currentDate,
      },
      statistics5: [],
      statistics5PickerOptions: {
        // 在这里设置最早可选时间
        disabledDate: (time) => {
          return (
            time.getTime() < this.statistics5EarliestPlayHourDate.getTime()
          );
        },
      },

      //官方曲谱
      statistics6EarliestDate: null,
      searchStatistics6: {
        timeLevel: "month",
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        hourDate: currentDate,
      },
      statistics6: [],

      //自定义曲谱
      statistics7EarliestDate: null,
      searchStatistics7: {
        timeLevel: "day",
        month: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? 12
            : currentDate.getMonth()
          : currentDate.getMonth() + 1,
        year: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear()
          : currentDate.getFullYear(),
        hourDate: currentDate,
      },
      statistics7: [],

      //配置
      statistics8EarliestDate: null,
      searchStatistics8: {
        timeLevel: "day",
        timeLevel: "day",
        month: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? 12
            : currentDate.getMonth()
          : currentDate.getMonth() + 1,
        year: isFirstDayOfMonth
          ? currentDate.getMonth() === 0
            ? currentDate.getFullYear() - 1
            : currentDate.getFullYear()
          : currentDate.getFullYear(),
        hourDate: currentDate,
      },
      statistics8: [],
    };
  },
  computed: {
    formattedUserCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.totalCount !== null &&
        this.statistics1.addCount !== null
      ) {
        return `${this.statistics1.totalCount} (+昨日 ${this.statistics1.addCount})`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedDeviceCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.deviceCount !== null &&
        this.statistics1.deviceAddCount !== null
      ) {
        return `${this.statistics1.deviceCount} (+昨日 ${this.statistics1.deviceAddCount})`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedSysSongCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.songCount !== null &&
        this.statistics1.songAddCount !== null
      ) {
        return `${this.statistics1.songCount} (+昨日 ${this.statistics1.songAddCount})`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedCusSongCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.customSongCount !== null &&
        this.statistics1.customSongAddCount !== null
      ) {
        return `pv:${this.statistics1.customSongCount}  uv:${this.statistics1.customSongUserCount}<br>(+昨日 ${this.statistics1.customSongAddCount})`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedCusSongCanViewCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (this.statistics1.csongCanView !== null) {
        return `${this.statistics1.csongCanView}`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedComonSettingCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.commonSettingCount !== null &&
        this.statistics1.commonSettingAddCount !== null
      ) {
        return `pv:${this.statistics1.commonSettingCount}  uv: ${this.statistics1.commonSettingUserCount}`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedSongSettingCount() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.commonSettingCount !== null &&
        this.statistics1.commonSettingAddCount !== null
      ) {
        return `pv:${this.statistics1.songSettingCount}  uv: ${this.statistics1.songSettingUserCount}`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    formattedPlayAdd() {
      // 如果数据不为 null，将 totalCount 和 addCount 相加并返回新的字符串
      if (
        this.statistics1.playAddCount !== null &&
        this.statistics1.playUserRangeCount !== null
      ) {
        return `pv:${this.statistics1.playAddCount}  uv: ${this.statistics1.playUserRangeCount}`;
      }
      // 如果数据为 null，返回空字符串或其他默认值
      return "";
    },

    //设备增量
    statistics2AvailableYears() {
      const years = [];
      if (this.statistics2EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics2EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics2AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics2EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics2EarliestDate) {
        startMonth =
          this.searchStatistics2.year ===
          this.statistics2EarliestDate.getFullYear()
            ? this.statistics2EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics2.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics2.month < startMonth) {
        this.searchStatistics2.month = startMonth;
      }
      return months;
    },

    //日活
    statistics3AvailableYears() {
      const years = [];
      if (this.statistics3EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics3EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics3AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics3EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics3EarliestDate) {
        startMonth =
          this.searchStatistics3.year ===
          this.statistics3EarliestDate.getFullYear()
            ? this.statistics3EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics3.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics3.month < startMonth) {
        this.searchStatistics3.month = startMonth;
      }
      return months;
    },

    // 用戶增量
    statistics4AvailableYears() {
      const years = [];
      if (this.statistics4EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics4EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics4AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics4EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics4EarliestDate) {
        startMonth =
          this.searchStatistics4.year ===
          this.statistics4EarliestDate.getFullYear()
            ? this.statistics4EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics4.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics4.month < startMonth) {
        this.searchStatistics4.month = startMonth;
      }
      return months;
    },

    // 弹唱
    statistics5AvailableYears() {
      const years = [];
      if (this.statistics5EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics5EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics5AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics5EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics5EarliestDate) {
        startMonth =
          this.searchStatistics5.year ===
          this.statistics5EarliestDate.getFullYear()
            ? this.statistics5EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics5.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics5.month < startMonth) {
        this.searchStatistics5.month = startMonth;
      }
      return months;
    },

    // 官方曲谱
    statistics6AvailableYears() {
      const years = [];
      if (this.statistics6EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics6EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics6AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics6EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics6EarliestDate) {
        startMonth =
          this.searchStatistics6.year ===
          this.statistics6EarliestDate.getFullYear()
            ? this.statistics6EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics6.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics6.month < startMonth) {
        this.searchStatistics6.month = startMonth;
      }
      return months;
    },

    // 自定义曲谱
    statistics7AvailableYears() {
      const years = [];
      if (this.statistics7EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics7EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics7AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics7EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics7EarliestDate) {
        startMonth =
          this.searchStatistics7.year ===
          this.statistics7EarliestDate.getFullYear()
            ? this.statistics7EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics7.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics7.month < startMonth) {
        this.searchStatistics7.month = startMonth;
      }
      return months;
    },

    // 配置
    statistics8AvailableYears() {
      const years = [];
      if (this.statistics8EarliestDate == null) {
        this.getOldestDate();
      }
      const currentYear = new Date().getFullYear();
      const isFirstDayOfYear =
        new Date().getDate() === 1 && new Date().getMonth() === 0;
      for (
        let year = this.statistics8EarliestDate?.getFullYear() || currentYear;
        year < currentYear;
        year++
      ) {
        years.push(year);
      }
      if (!isFirstDayOfYear) {
        years.push(currentYear);
      }
      return years;
    },
    statistics8AvailableMonths() {
      const months = [];
      var startMonth;
      var endMonth;
      if (this.statistics8EarliestDate == null) {
        this.getOldestDate();
      }
      if (this.statistics8EarliestDate) {
        startMonth =
          this.searchStatistics8.year ===
          this.statistics8EarliestDate.getFullYear()
            ? this.statistics8EarliestDate.getMonth() + 1
            : 1;
        if (this.searchStatistics8.year === new Date().getFullYear()) {
          const isFirstDayOfMonth = new Date().getDate() === 1; //是否是当月的第一天
          endMonth = isFirstDayOfMonth
            ? new Date().getMonth()
            : new Date().getMonth() + 1;
        } else {
          endMonth = 12;
        }
      } else {
        startMonth = 1;
        endMonth = 12;
      }
      for (let month = startMonth; month <= endMonth; month++) {
        months.push(month);
      }
      if (this.searchStatistics8.month < startMonth) {
        this.searchStatistics8.month = startMonth;
      }
      return months;
    },
  },
  mounted() {
    this.initData();
    this.getStatistics1();
    this.handlePie1();
    this.handlePie2();
    this.handleSearchStatistics2();
    this.handleSearchStatistics3();
    this.handleSearchStatistics4();
    this.handleSearchStatistics5();
    this.handleSearchStatistics6();
    this.handleSearchStatistics7();
    this.handleSearchStatistics8();
  },
  methods: {
    async initData() {
      await this.getOldestDate();
      // 其他初始化逻辑
    },
    formatDateHH(time) {
      let data = time;
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, "0"); // Add zero padding
      let quarter = Math.floor(month / 3);
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding
      let hours = dt.getHours().toString(); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding
      if (hours == 0) {
        hours = 24;
      }
      return `${hours}:${minutes}`;
    },
    formatDateMMdd(time) {
      let data = time;
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, "0"); // Add zero padding
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding
      let quarter = Math.floor(month / 3);
      let hours = dt.getHours().toString().padStart(2, "0"); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding

      return `${month}.${day}`;
    },
    formatDateMM(time) {
      let data = time;
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString();
      let quarter = Math.floor(month / 3);
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding
      let hours = dt.getHours().toString().padStart(2, "0"); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding
      return `${month}月`;
    },
    formatDateQuarter(time) {
      let data = time;
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString();
      let quarter = Math.floor(month / 3);
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding
      let hours = dt.getHours().toString().padStart(2, "0"); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding
      return `${quarter}季度`;
    },
    formatDateYear(time) {
      let data = time;
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString();
      let quarter = Math.floor(month / 3);
      let day = dt.getDate().toString().padStart(2, "0"); // Add zero padding
      let hours = dt.getHours().toString().padStart(2, "0"); // Add zero padding
      let minutes = dt.getMinutes().toString().padStart(2, "0"); // Add zero padding
      let seconds = dt.getSeconds().toString().padStart(2, "0"); // Add zero padding
      return `${year}`;
    },

    // 计算最早时间
    getOldestDate() {
      return new Promise(async (resolve, reject) => {
        try {
          const resp = await this.$_api.BOARD_RECORDOLDESTDATE(
            this.searchStatistics2
          );
          this.$set(
            this,
            "statistics2EarliestDate",
            new Date(resp.data.data.device)
          ); //响应式设置值
          this.$set(
            this,
            "statistics3EarliestDate",
            new Date(resp.data.data.active)
          ); //响应式设置值
          this.$set(
            this,
            "statistics4EarliestDate",
            new Date(resp.data.data.user)
          ); //响应式设置值
          this.$set(
            this,
            "statistics5EarliestDate",
            new Date(resp.data.data.play)
          ); //响应式设置值
          this.$set(
            this,
            "statistics5EarliestPlayHourDate",
            new Date(resp.data.data.playHour)
          ); //响应式设置值
          this.$set(
            this,
            "statistics6EarliestDate",
            new Date(resp.data.data.sysSong)
          ); //响应式设置值
          this.$set(
            this,
            "statistics7EarliestDate",
            new Date(resp.data.data.customSong)
          ); //响应式设置值
          this.$set(
            this,
            "statistics8EarliestDate",
            new Date(resp.data.data.commonSetting)
          ); //响应式设置值

          resolve(this.statistics2EarliestDate);
        } catch (error) {
          reject(error);
        }
      });
    },

    // 获取统计数据
    async getStatistics1() {
      await this.$_api
        .BOARD_STATISTICS1()
        .then((resp) => {
          //设置页面数据
          this.statistics1 = resp.data.data;
          this.statistics1.deviceCountStr =
            this.statistics1.deviceCount +
            "(+昨日新增" +
            this.statistics1.deviceAddCount +
            ")";
          // console.log(this.statistics1, "statistics1")
        })
        .catch();
      return this.statistics1;
    },

    // 获取统计数据
    async getPie2() {
      await this.$_api
        .BOARD_PIE2()
        .then((resp) => {
          //设置页面数据
          this.pie2 = resp.data.data;
          //   console.log(this.pie2, "pie2");
        })
        .catch();
      return this.pie2;
    },

    //ios和android的饼图
    drawPie1() {
      const pie1 = echarts.init(this.$refs.pie1);
      // //指定图标的配置项和数据
      // this.statistics2.forEach(item => {
      //     xdata.push(this.formatDateMMdd(item.date));
      //     ydata.push(item.addCount);
      // });
      var echarts2Option = {
        title: {
          text: "用戶占比",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //     top: '2%',
        //     left: 'center'
        // },
        series: [
          {
            type: "pie",
            avoidLabelOverlap: false,
            // label: {
            //     show: true,
            //     position: 'center'
            // },
            labelLine: {
              show: true,
            },
            data: [
              { value: this.statistics1.iosCount, name: "ios" },
              { value: this.statistics1.androidCount, name: "android" },
            ],
            smooth: true, //让线变平缓
          },
        ],
      };
      pie1.setOption(echarts2Option);
    },

    drawPie2() {
      const pie2 = echarts.init(this.$refs.pie2);
      var map = this.pie2;
      var pieData = Object.keys(map).map(function (date) {
        return { value: map[date], name: date };
      });

      var echarts2Option = {
        title: {
          text: "版本占比",
        },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //     top: '2%',
        //     left: 'center'
        // },
        series: [
          {
            type: "pie",
            avoidLabelOverlap: false,
            // label: {
            //     show: true,
            //     position: 'center'
            // },
            labelLine: {
              show: true,
            },
            data: pieData,
          },
        ],
      };
      pie2.setOption(echarts2Option);
    },
    handlePie1() {
      this.getStatistics1().then(({ data }) => {
        this.drawPie1();
      });
    },
    handlePie2() {
      this.getPie2().then(({ data }) => {
        this.drawPie2();
      });
    },

    // 获取统计数据
    async getStatistics2() {
      await this.$_api
        .BOARD_STATISTICS2(this.searchStatistics2)
        .then((resp) => {
          //设置页面数据
          this.statistics2 = resp.data.data;
        })
        .catch();
      return this.statistics2;
    },
    drawStatistics2() {
      const echarts2 = echarts.init(this.$refs.echarts2);
      // //指定图标的配置项和数据
      const xdata = [];
      const ydata = [];
      this.statistics2.forEach((item) => {
        if (this.searchStatistics2.timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (this.searchStatistics2.timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (this.searchStatistics2.timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (this.searchStatistics2.timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (this.searchStatistics2.timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        ydata.push(item.addCount);
      });
      var echarts2Option = {
        title: {
          text: "设备增量",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        series: [
          {
            type: "line",
            data: ydata,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts2.setOption(echarts2Option);
    },
    handleSearchStatistics2() {
      if (this.statistics2EarliestDate) {
        if (this.searchStatistics2.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics2.year,
              this.searchStatistics2.month,
              this.statistics2EarliestDate.getFullYear(),
              this.statistics2EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics2.year =
              this.statistics2EarliestDate.getFullYear();
            this.searchStatistics2.month =
              this.statistics2EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics2.year,
              this.searchStatistics2.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics2.year = currentYear - 1;
                this.searchStatistics2.month = 12;
              } else {
                this.searchStatistics2.year = currentYear;
                this.searchStatistics2.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics2.year = currentYear;
              this.searchStatistics2.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics2.year < this.statistics2EarliestDate.year) {
            this.searchStatistics2.year = this.statistics2EarliestDate.year;
          }
        }
      }
      this.getStatistics2().then(({ data }) => {
        this.drawStatistics2();
      });
    },

    //日活数据
    async getStatistics3() {
      await this.$_api
        .BOARD_STATISTICS3(this.searchStatistics3)
        .then((resp) => {
          //设置页面数据
          this.statistics3 = resp.data.data;
        })
        .catch();
      return this.statistics3;
    },
    drawStatistics3() {
      const echarts3 = echarts.init(this.$refs.echarts3);
      // //指定图标的配置项和数据
      const xdata = [];
      const yActivedata = [];
      const yIosActivedata = [];
      const yAndroidActivedata = [];
      this.statistics3.forEach((item) => {
        if (this.searchStatistics3.timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (this.searchStatistics3.timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (this.searchStatistics3.timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (this.searchStatistics3.timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (this.searchStatistics3.timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yActivedata.push(item.active);
        yIosActivedata.push(item.iosActive);
        yAndroidActivedata.push(item.androidActive);
      });
      var echarts3Option = {
        title: {
          text: "日活",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["日活", "ios日活", "android日活"],
        },
        series: [
          {
            name: "日活",
            type: "line",
            data: yActivedata,
            smooth: true, //让线变平缓
          },
          {
            name: "ios日活",
            type: "line",
            data: yIosActivedata,
            smooth: true, //让线变平缓
          },
          {
            name: "android日活",
            type: "line",
            data: yAndroidActivedata,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts3.setOption(echarts3Option);
    },
    compareDates(year1, month1, year2, month2) {
      if (year1 === year2) {
        return month1 - month2;
      } else {
        return year1 - year2;
      }
    },
    handleSearchStatistics3() {
      if (this.statistics3EarliestDate) {
        if (this.searchStatistics3.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics3.year,
              this.searchStatistics3.month,
              this.statistics3EarliestDate.getFullYear(),
              this.statistics3EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics3.year =
              this.statistics3EarliestDate.getFullYear();
            this.searchStatistics3.month =
              this.statistics3EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics3.year,
              this.searchStatistics3.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics3.year = currentYear - 1;
                this.searchStatistics3.month = 12;
              } else {
                this.searchStatistics3.year = currentYear;
                this.searchStatistics3.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics3.year = currentYear;
              this.searchStatistics3.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics3.year < this.statistics3EarliestDate.year) {
            this.searchStatistics3.year = this.statistics3EarliestDate.year;
          }
        }
      }
      this.getStatistics3().then(({ data }) => {
        this.drawStatistics3();
      });
    },

    //用户增量
    async getStatistics4() {
      await this.$_api
        .BOARD_STATISTICS4(this.searchStatistics4)
        .then((resp) => {
          //设置页面数据
          this.statistics4 = resp.data.data;
        })
        .catch();
      return this.statistics4;
    },
    drawStatistics4() {
      const echarts4 = echarts.init(this.$refs.echarts4);
      // //指定图标的配置项和数据
      const xdata = [];
      const yActivedata = [];
      const yIosActivedata = [];
      const yAndroidActivedata = [];
      this.statistics4.forEach((item) => {
        if (this.searchStatistics4.timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (this.searchStatistics4.timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (this.searchStatistics4.timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (this.searchStatistics4.timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (this.searchStatistics4.timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yActivedata.push(item.add);
        yIosActivedata.push(item.iosAdd);
        yAndroidActivedata.push(item.androidAdd);
      });
      var echarts4Option = {
        title: {
          text: "用户增量",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["总增量", "ios增量", "android增量"],
        },
        series: [
          {
            name: "总增量",
            type: "line",
            data: yActivedata,
            smooth: true, //让线变平缓
          },
          {
            name: "ios增量",
            type: "line",
            data: yIosActivedata,
            smooth: true, //让线变平缓
          },
          {
            name: "android增量",
            type: "line",
            data: yAndroidActivedata,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts4.setOption(echarts4Option);
    },
    handleSearchStatistics4() {
      if (this.statistics4EarliestDate) {
        if (this.searchStatistics4.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics4.year,
              this.searchStatistics4.month,
              this.statistics4EarliestDate.getFullYear(),
              this.statistics4EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics4.year =
              this.statistics4EarliestDate.getFullYear();
            this.searchStatistics4.month =
              this.statistics4EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics4.year,
              this.searchStatistics4.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics4.year = currentYear - 1;
                this.searchStatistics4.month = 12;
              } else {
                this.searchStatistics4.year = currentYear;
                this.searchStatistics4.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics4.year = currentYear;
              this.searchStatistics4.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics4.year < this.statistics4EarliestDate.year) {
            this.searchStatistics4.year = this.statistics4EarliestDate.year;
          }
        }
      }
      this.getStatistics4().then(({ data }) => {
        this.drawStatistics4();
      });
    },

    //弹唱
    async getStatistics5() {
      await this.$_api
        .BOARD_STATISTICS5(this.searchStatistics5)
        .then((resp) => {
          //设置页面数据
          this.statistics5 = resp.data.data;
        })
        .catch();
      return this.statistics5;
    },
    drawStatistics5() {
      const echarts5 = echarts.init(this.$refs.echarts5);
      // //指定图标的配置项和数据
      const xdata = [];
      const yPlayAdd = [];
      const yPlayUserAdd = [];
      this.statistics5.forEach((item) => {
        if (this.searchStatistics5.timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (this.searchStatistics5.timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (this.searchStatistics5.timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (this.searchStatistics5.timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (this.searchStatistics5.timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yPlayAdd.push(item.playAdd);
        yPlayUserAdd.push(item.playUserRange);
      });
      var echarts5Option = {
        title: {
          text: "弹唱",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["弹唱数", "弹唱用户"],
        },
        series: [
          {
            name: "弹唱数",
            type: "line",
            data: yPlayAdd,
            smooth: true, //让线变平缓
          },
          {
            name: "弹唱用户",
            type: "line",
            data: yPlayUserAdd,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts5.setOption(echarts5Option);
    },
    handleSearchStatistics5() {
      if (this.statistics5EarliestDate) {
        if (this.searchStatistics5.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics5.year,
              this.searchStatistics5.month,
              this.statistics5EarliestDate.getFullYear(),
              this.statistics5EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics5.year =
              this.statistics5EarliestDate.getFullYear();
            this.searchStatistics5.month =
              this.statistics5EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics5.year,
              this.searchStatistics5.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics5.year = currentYear - 1;
                this.searchStatistics5.month = 12;
              } else {
                this.searchStatistics5.year = currentYear;
                this.searchStatistics5.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics5.year = currentYear;
              this.searchStatistics5.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics5.year < this.statistics5EarliestDate.year) {
            this.searchStatistics5.year = this.statistics5EarliestDate.year;
          }
        }
      }
      if (this.searchStatistics5.timeLevel === "hour") {
        if (!this.searchStatistics5.hourDate) {
          return;
        }
      }
      this.getStatistics5().then(({ data }) => {
        this.drawStatistics5();
      });
    },

    //官方曲谱
    async getStatistics6() {
      await this.$_api
        .BOARD_STATISTICS6(this.searchStatistics6)
        .then((resp) => {
          //设置页面数据
          this.statistics6 = resp.data.data;
        })
        .catch();
      return this.statistics6AvailableMonths;
    },
    drawStatistics6() {
      const echarts6 = echarts.init(this.$refs.echarts6);
      // //指定图标的配置项和数据
      const xdata = [];
      const yAdd = [];
      let timeLevel = this.searchStatistics6.timeLevel;

      this.statistics6.forEach((item) => {
        if (timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yAdd.push(item.add);
      });
      var echartsOption = {
        title: {
          text: "官方曲谱增量",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["官方曲谱"],
        },
        series: [
          {
            name: "官方曲谱",
            type: "line",
            data: yAdd,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts6.setOption(echartsOption);
    },
    handleSearchStatistics6() {
      if (this.statistics6EarliestDate) {
        if (this.searchStatistics6.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics6.year,
              this.searchStatistics6.month,
              this.statistics6EarliestDate.getFullYear(),
              this.statistics6EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics6.year =
              this.statistics6EarliestDate.getFullYear();
            this.searchStatistics6.month =
              this.statistics6EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics6.year,
              this.searchStatistics6.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics6.year = currentYear - 1;
                this.searchStatistics6.month = 12;
              } else {
                this.searchStatistics6.year = currentYear;
                this.searchStatistics6.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics6.year = currentYear;
              this.searchStatistics6.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics6.year < this.statistics6EarliestDate.year) {
            this.searchStatistics6.year = this.statistics6EarliestDate.year;
          }
        }
      }
      if (this.searchStatistics6.timeLevel === "hour") {
        if (!this.searchStatistics6.hourDate) {
          return;
        }
      }
      this.getStatistics6().then(({ data }) => {
        this.drawStatistics6();
      });
    },

    //自定义曲谱
    async getStatistics7() {
      await this.$_api
        .BOARD_STATISTICS7(this.searchStatistics7)
        .then((resp) => {
          //设置页面数据
          this.statistics7 = resp.data.data;
        })
        .catch();
      return this.statistics7AvailableMonths;
    },
    drawStatistics7() {
      const echarts7 = echarts.init(this.$refs.echarts7);
      // //指定图标的配置项和数据
      const xdata = [];
      const yAdd = [];
      const uv = [];
      let timeLevel = this.searchStatistics7.timeLevel;

      this.statistics7.forEach((item) => {
        if (timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yAdd.push(item.add);
        uv.push(item.uv);
      });
      var echartsOption = {
        title: {
          text: "自定义曲谱增量",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["自定义曲谱"],
        },
        series: [
          {
            name: "pv",
            type: "line",
            data: yAdd,
            smooth: true, //让线变平缓
          },
          {
            name: "uv",
            type: "line",
            data: uv,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts7.setOption(echartsOption);
    },
    handleSearchStatistics7() {
      if (this.statistics7EarliestDate) {
        if (this.searchStatistics7.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics7.year,
              this.searchStatistics7.month,
              this.statistics7EarliestDate.getFullYear(),
              this.statistics7EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics7.year =
              this.statistics7EarliestDate.getFullYear();
            this.searchStatistics7.month =
              this.statistics7EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics7.year,
              this.searchStatistics7.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics7.year = currentYear - 1;
                this.searchStatistics7.month = 12;
              } else {
                this.searchStatistics7.year = currentYear;
                this.searchStatistics7.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics7.year = currentYear;
              this.searchStatistics7.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics7.year < this.statistics7EarliestDate.year) {
            this.searchStatistics7.year = this.statistics7EarliestDate.year;
          }
        }
      }
      if (this.searchStatistics7.timeLevel === "hour") {
        if (!this.searchStatistics7.hourDate) {
          return;
        }
      }
      this.getStatistics7().then(({ data }) => {
        this.drawStatistics7();
      });
    },

    //配置
    async getStatistics8() {
      await this.$_api
        .BOARD_STATISTICS8(this.searchStatistics8)
        .then((resp) => {
          //设置页面数据
          this.statistics8 = resp.data.data;
        })
        .catch();
      return this.statistics8AvailableMonths;
    },
    drawStatistics8() {
      const echarts8 = echarts.init(this.$refs.echarts8);
      // //指定图标的配置项和数据
      const xdata = [];
      const yAddCommonSettingPv = [];
      const yAddCommonSettingUv = [];
      const yAddSongSettingPv = [];
      const yAddSongSettingUv = [];
      let timeLevel = this.searchStatistics8.timeLevel;

      this.statistics8.forEach((item) => {
        if (timeLevel === "hour") {
          xdata.push(this.formatDateHH(item.date));
        } else if (timeLevel === "day") {
          xdata.push(this.formatDateMMdd(item.date));
        } else if (timeLevel === "month") {
          xdata.push(this.formatDateMM(item.date));
        } else if (timeLevel === "quarter") {
          xdata.push(this.formatDateQuarter(item.date));
        } else if (timeLevel === "year") {
          xdata.push(this.formatDateYear(item.date));
        }
        yAddCommonSettingPv.push(item.commonSettingAddCount);
        yAddCommonSettingUv.push(item.commonSettingUserRangeCount);
        yAddSongSettingPv.push(item.songSettingAddCount);
        yAddSongSettingUv.push(item.songSettingUserRangeCount);
      });
      var echartsOption = {
        title: {
          text: "配置",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xdata,
        },
        yAxis: {},
        legend: {
          data: ["通用配置pv", "通用配置uv", "曲谱配置pv", "曲谱配置uv"],
        },
        series: [
          {
            name: "通用配置pv",
            type: "line",
            data: yAddCommonSettingPv,
            smooth: true, //让线变平缓
          },
          {
            name: "通用配置uv",
            type: "line",
            data: yAddCommonSettingUv,
            smooth: true, //让线变平缓
          },
          {
            name: "曲谱配置pv",
            type: "line",
            data: yAddSongSettingPv,
            smooth: true, //让线变平缓
          },
          {
            name: "曲谱配置uv",
            type: "line",
            data: yAddSongSettingUv,
            smooth: true, //让线变平缓
          },
        ],
      };
      echarts8.setOption(echartsOption);
    },
    handleSearchStatistics8() {
      if (this.statistics8EarliestDate) {
        if (this.searchStatistics8.timeLevel === "day") {
          // 当year和month组合而成的时间小于earliestDate
          if (
            this.compareDates(
              this.searchStatistics8.year,
              this.searchStatistics8.month,
              this.statistics8EarliestDate.getFullYear(),
              this.statistics8EarliestDate.getMonth() + 1
            ) < 0
          ) {
            this.searchStatistics8.year =
              this.statistics8EarliestDate.getFullYear();
            this.searchStatistics8.month =
              this.statistics8EarliestDate.getMonth() + 1;
          }
          //当year和month组合而成的时间大于当前时间 那就让year和month设置为当前时间对应的year和month
          // 当year和month组合而成的时间大于当前时间
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          const isFirstDayOfMonth = new Date().getDate() === 1;
          if (
            this.compareDates(
              this.searchStatistics8.year,
              this.searchStatistics8.month,
              currentYear,
              currentMonth
            ) > 0
          ) {
            if (isFirstDayOfMonth) {
              //如果是当月的第一天
              if (currentMonth == 1) {
                //如果是一月份
                this.searchStatistics8.year = currentYear - 1;
                this.searchStatistics8.month = 12;
              } else {
                this.searchStatistics8.year = currentYear;
                this.searchStatistics8.month = currentMonth - 1;
              }
            } else {
              this.searchStatistics8.year = currentYear;
              this.searchStatistics8.month = currentMonth;
            }
          }
        } else {
          // 当year小于earliestDate
          if (this.searchStatistics8.year < this.statistics8EarliestDate.year) {
            this.searchStatistics8.year = this.statistics8EarliestDate.year;
          }
        }
      }
      if (this.searchStatistics8.timeLevel === "hour") {
        if (!this.searchStatistics8.hourDate) {
          return;
        }
      }
      this.getStatistics8().then(({ data }) => {
        this.drawStatistics8();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;

  img {
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .userinfo {
    .name {
      font-size: 32px;
      margin-bottom: 10px;
    }

    .access {
      color: #999999;
    }
  }
}

.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;

    span {
      color: #666666;
      margin-left: 60px;
    }
  }
}

.num {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .icon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }

  .detail {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .price {
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 30px;
    height: 30px;
  }

  .desc {
    font-size: 14px;
    color: #999;
    text-align: center;
  }

  .el-card {
    width: 32%;
    margin-bottom: 20px;
  }
}

.graph {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .el-card {
    width: 48%;
  }
}

.icon-large {
  font-size: 24px;
}
</style>
