<template>
  <div class="transfer-wrap">
    <!--左侧框-->
    <div>
      <div class="my-transfer-panel-title">{{ leftTitle }}</div>
      <div class="my-transfer-panel">
        <template v-for="(item, index) in local_left_data">
          <div v-if="item.not_move" :key="index" class="my-transfer-item">
            {{ item.name ? item.name : item.label ? item.label : item }}
          </div>
          <div
            v-else
            :key="index + '1'"
            :class="[
              'my-transfer-item',
              left_select.indexOf(index) == -1 ? '' : 'my-transfer-item-select',
            ]"
            :title="item.name ? item.name : item.label ? item.label : item"
            @click="selectItem(left_select, index)"
          >
            <span />
            {{ item.name ? item.name : item.label ? item.label : item }}
          </div>
        </template>
      </div>
    </div>
    <!--操作按钮组-->
    <div class="transfer-button-wrap">
      <div>
        <el-button
          v-if="canSort"
          type="primary"
          size="mini"
          icon="el-icon-arrow-up"
          @click="move_up"
        />
      </div>
      <div>
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-d-arrow-left"
          @click="move_left"
        />
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-d-arrow-right"
          @click="move_right"
        />
      </div>
      <div>
        <el-button
          v-if="canSort"
          type="primary"
          size="mini"
          icon="el-icon-arrow-down"
          @click="move_down"
        />
      </div>
    </div>
    <!--右侧框-->
    <div>
      <div class="my-transfer-panel-title">{{ rightTitle }}</div>
      <div class="my-transfer-panel">
        <template v-for="(item, index) in local_right_data">
          <div v-if="item.not_move" :key="index" class="my-transfer-item">
            {{ item.name ? item.name : item.label ? item.label : item }}
          </div>
          <div
            v-else
            :key="index + '2'"
            :class="[
              'my-transfer-item',
              right_select.indexOf(index) == -1
                ? ''
                : 'my-transfer-item-select',
            ]"
            :title="item.name ? item.name : item.label ? item.label : item"
            @click="selectItem(right_select, index)"
          >
            <span />
            {{ item.name ? item.name : item.label ? item.label : item }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransferWithSort",
  props: {
    // 是否带排序按钮
    canSort: {
      type: Boolean,
      default: false,
    },
    // 左侧框头部标题
    leftTitle: {
      type: String,
      default: undefined,
    },
    // 右侧框头部标题
    rightTitle: {
      type: String,
      default: undefined,
    },
    // 左侧框内的数组
    leftData: {
      type: Array,
      default: undefined,
    },
    // 右侧框内的数组
    rightData: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      /**
       * left_select:左侧选中元素的下标
       * right_select:右侧选中元素的下标
       * local_left_data:组件内的左侧数据
       * local_right_data:组件内的右侧数据
       *  **/
      left_select: [],
      right_select: [],
      local_left_data: [],
      local_right_data: [],
    };
  },
  watch: {
    // 同时监听组件内的数据变动和父组件的数据变动以做到父子组件数据双向绑定
    leftData(val) {
      this.local_left_data = val;
    },
    local_left_data(val) {
      this.$emit("update:leftData", val);
    },
    rightData(val) {
      this.local_right_data = val;
    },
    local_right_data(val) {
      this.$emit("update:rightData", val);
    },
  },
  created() {
    // 组件初始化时同步下父子组件的数据
    this.local_left_data = this.leftData;
    this.local_right_data = this.rightData;
  },
  methods: {
    // 点击元素进行选中或取消选中
    selectItem(select_list, item) {
      const idx = select_list.indexOf(item);
      if (idx === -1) {
        select_list.push(item);
      } else {
        select_list.splice(idx, 1);
      }
    },
    // 左侧框的数据移动到右侧框
    move_right() {
      this.left_select.sort();
      const temp_arr = [];
      for (let i = 0; i < this.left_select.length; i++) {
        temp_arr.push(this.local_left_data[this.left_select[i]]);
      }
      this.local_right_data.push(...temp_arr);
      temp_arr.forEach((item) => {
        this.local_left_data.splice(this.local_left_data.indexOf(item), 1);
      });
      this.left_select = [];
      this.$emit("handleChange", this.leftData, this.rightData);
    },
    // 右侧框的数据移动到左侧框
    move_left() {
      this.right_select.sort();
      const temp_arr = [];
      for (let i = 0; i < this.right_select.length; i++) {
        temp_arr.push(this.local_right_data[this.right_select[i]]);
      }
      this.local_left_data.push(...temp_arr);
      temp_arr.forEach((item) => {
        this.local_right_data.splice(this.local_right_data.indexOf(item), 1);
      });
      this.right_select = [];
      this.$emit("handleChange", this.leftData, this.rightData);
    },
    // 元素排序向上移动
    move_up() {
      // 这里对两个数组进行排序主要是避免移动位置时数据顺序错乱
      // this.left_select.sort()
      this.right_select.sort();
      // 左侧的数据上移
      // for (let i = 0; i < this.left_select.length; i++) {
      //   const idx = this.left_select[i]
      //   // 如果选中了第一个元素，那么直接跳出，不进行移动
      //   if (idx === 0) {
      //     break
      //   } else {
      //     // 解构替换数组内两个元素的位置
      //     [this.local_left_data[idx - 1], this.local_left_data[idx]] = [
      //       this.local_left_data[idx],
      //       this.local_left_data[idx - 1]
      //     ]
      //     this.left_select[i] = idx - 1
      //   }
      // }
      // 右侧数据上移
      for (let i = 0; i < this.right_select.length; i++) {
        const idx = this.right_select[i];
        if (idx === 0) {
          break;
        } else {
          // 解构替换数组内两个元素的位置
          [this.local_right_data[idx - 1], this.local_right_data[idx]] = [
            this.local_right_data[idx],
            this.local_right_data[idx - 1],
          ];
          this.right_select[i] = idx - 1;
        }
      }
      this.$emit("handleSort", this.leftData, this.rightData);
    },
    // 元素排序向下移动
    move_down() {
      this.left_select.sort();
      this.right_select.sort();
      // 左侧数据下移，从数组的最后一位开始是因为，元素要向下移，
      // 必然是当前位置和当前位置下标+1的元素进行替换，如果不从最后一位开始替换，
      // 那么多选的情况下就可能造成排序混乱。
      // for (let i = this.left_select.length - 1; i >= 0; i--) {
      //   const idx = this.left_select[i]
      //   // 如果选中了最后一个元素，那么直接跳出，不进行移动
      //   if (idx === this.local_left_data.length - 1) {
      //     break
      //   } else {
      //     // 解构替换数组内两个元素的位置
      //     [this.local_left_data[idx + 1], this.local_left_data[idx]] = [
      //       this.local_left_data[idx],
      //       this.local_left_data[idx + 1]
      //     ]
      //     this.left_select[i] = idx + 1
      //   }
      // }
      // 右侧数据下移
      for (let i = this.right_select.length - 1; i >= 0; i--) {
        const idx = this.right_select[i];
        if (idx === this.local_right_data.length - 1) {
          break;
        } else {
          // 解构替换数组内两个元素的位置
          [this.local_right_data[idx + 1], this.local_right_data[idx]] = [
            this.local_right_data[idx],
            this.local_right_data[idx + 1],
          ];
          this.right_select[i] = idx + 1;
        }
      }
      this.$emit("handleSort", this.leftData, this.rightData);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-transfer-panel {
  border: 1px solid #dcdfe6;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 10px;
  width: 150px;
  height: 200px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .my-transfer-item {
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    &:hover {
      color: #3498fc;
    }
    span {
      display: inline-block;
      position: relative;
      border: 1px solid #dcdfe6;
      border-radius: 2px;
      box-sizing: border-box;
      width: 14px;
      height: 14px;
      background-color: #fff;
      z-index: 1;

      &::before {
        box-sizing: content-box;
        content: "";
        border: 1px solid #fff;
        border-left: 0;
        border-top: 0;
        height: 7px;
        left: 4px;
        position: absolute;
        top: 1px;
        width: 3px;
        transform-origin: center;
        transform: rotate(45deg) scaleY(1);
      }
    }
  }
  .my-transfer-item-select {
    color: #409eff;
    &:hover {
      color: #0a335c;
    }
    span {
      &::after {
        transform: rotate(45deg) scaleY(1);
      }
      background: #409eff;
    }
  }
}
.transfer-wrap {
  display: flex;
  .transfer-button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
  }
}
.my-transfer-panel-title {
  text-align: center;
}
</style>
