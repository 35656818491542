var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { staticStyle: { "padding-left": "10px" }, attrs: { span: 24 } },
        [
          _c(
            "el-card",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-row", [
                    _c("i", { class: ["el-icon-data-line", "icon-large"] }),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "用户总数" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.formattedUserCount) + " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "设备总数" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.formattedDeviceCount) + " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "官方曲谱总数" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formattedSysSongCount) +
                                      " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "自定义曲谱总数" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formattedCusSongCount
                                      ),
                                    },
                                  }),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "自定义曲谱开放" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.formattedCusSongCanViewCount
                                      ),
                                    },
                                  }),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "通用配置" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formattedComonSettingCount) +
                                      " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "曲谱专属配置" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formattedSongSettingCount) +
                                      " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c("el-statistic", {
                              attrs: {
                                "group-separator": ",",
                                precision: 2,
                                value: this.statistics1.averageSongCountOfSheet,
                                title: "歌单平均曲谱数",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-statistic",
                              { attrs: { title: "昨日弹唱" } },
                              [
                                _c("template", { slot: "formatter" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.formattedPlayAdd) + " "
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", {
                  ref: "pie1",
                  staticStyle: { flex: "1", height: "180px" },
                }),
                _c("div", {
                  ref: "pie2",
                  staticStyle: { flex: "1", height: "180px" },
                }),
              ]),
            ],
            1
          ),
          _c(
            "el-card",
            { staticStyle: { "margin-top": "15px", height: "380px" } },
            [
              _c("div", { ref: "echarts2", staticStyle: { height: "300px" } }),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.searchStatistics2 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "时间类型" },
                          on: { change: _vm.handleSearchStatistics2 },
                          model: {
                            value: _vm.searchStatistics2.timeLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchStatistics2, "timeLevel", $$v)
                            },
                            expression: "searchStatistics2.timeLevel",
                          },
                        },
                        _vm._l(_vm.$_options["timeLevel_1"], function (item) {
                          return _c("el-option", {
                            key: item[1],
                            attrs: { label: item[0], value: item[1] },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "年份" },
                          on: { change: _vm.handleSearchStatistics2 },
                          model: {
                            value: _vm.searchStatistics2.year,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchStatistics2, "year", $$v)
                            },
                            expression: "searchStatistics2.year",
                          },
                        },
                        _vm._l(_vm.statistics2AvailableYears, function (year) {
                          return _c("el-option", {
                            key: year,
                            attrs: { label: year, value: year },
                          })
                        }),
                        1
                      ),
                      _vm.searchStatistics2.timeLevel === "day"
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: "月份" },
                              on: { change: _vm.handleSearchStatistics2 },
                              model: {
                                value: _vm.searchStatistics2.month,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics2, "month", $$v)
                                },
                                expression: "searchStatistics2.month",
                              },
                            },
                            _vm._l(
                              _vm.statistics2AvailableMonths,
                              function (month) {
                                return _c("el-option", {
                                  key: month,
                                  attrs: { label: month, value: month },
                                })
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticStyle: { "margin-top": "15px", height: "380px" } },
            [
              _c("div", { ref: "echarts3", staticStyle: { height: "300px" } }),
              _c(
                "el-form",
                { attrs: { inline: true, model: _vm.searchStatistics3 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "时间类型" },
                          on: { change: _vm.handleSearchStatistics3 },
                          model: {
                            value: _vm.searchStatistics3.timeLevel,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchStatistics3, "timeLevel", $$v)
                            },
                            expression: "searchStatistics3.timeLevel",
                          },
                        },
                        _vm._l(_vm.$_options["timeLevel_2"], function (item) {
                          return _c("el-option", {
                            key: item[1],
                            attrs: { label: item[0], value: item[1] },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "年份" },
                          on: { change: _vm.handleSearchStatistics3 },
                          model: {
                            value: _vm.searchStatistics3.year,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchStatistics3, "year", $$v)
                            },
                            expression: "searchStatistics3.year",
                          },
                        },
                        _vm._l(_vm.statistics3AvailableYears, function (year) {
                          return _c("el-option", {
                            key: year,
                            attrs: { label: year, value: year },
                          })
                        }),
                        1
                      ),
                      _vm.searchStatistics3.timeLevel === "day"
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: "月份" },
                              on: { change: _vm.handleSearchStatistics3 },
                              model: {
                                value: _vm.searchStatistics3.month,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics3, "month", $$v)
                                },
                                expression: "searchStatistics3.month",
                              },
                            },
                            _vm._l(
                              _vm.statistics3AvailableMonths,
                              function (month) {
                                return _c("el-option", {
                                  key: month,
                                  attrs: { label: month, value: month },
                                })
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px", "padding-right": "10px" },
              attrs: { span: 12 },
            },
            [
              _c(
                "el-card",
                { staticStyle: { height: "380px" } },
                [
                  _c("div", {
                    ref: "echarts4",
                    staticStyle: { height: "300px" },
                  }),
                  _c(
                    "el-form",
                    { attrs: { inline: true, model: _vm.searchStatistics4 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "时间类型" },
                              on: { change: _vm.handleSearchStatistics4 },
                              model: {
                                value: _vm.searchStatistics4.timeLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchStatistics4,
                                    "timeLevel",
                                    $$v
                                  )
                                },
                                expression: "searchStatistics4.timeLevel",
                              },
                            },
                            _vm._l(
                              _vm.$_options["timeLevel_1"],
                              function (item) {
                                return _c("el-option", {
                                  key: item[1],
                                  attrs: { label: item[0], value: item[1] },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "年份" },
                              on: { change: _vm.handleSearchStatistics4 },
                              model: {
                                value: _vm.searchStatistics4.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics4, "year", $$v)
                                },
                                expression: "searchStatistics4.year",
                              },
                            },
                            _vm._l(
                              _vm.statistics4AvailableYears,
                              function (year) {
                                return _c("el-option", {
                                  key: year,
                                  attrs: { label: year, value: year },
                                })
                              }
                            ),
                            1
                          ),
                          _vm.searchStatistics4.timeLevel === "day"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "月份" },
                                  on: { change: _vm.handleSearchStatistics4 },
                                  model: {
                                    value: _vm.searchStatistics4.month,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics4,
                                        "month",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics4.month",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics4AvailableMonths,
                                  function (month) {
                                    return _c("el-option", {
                                      key: month,
                                      attrs: { label: month, value: month },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "20px", height: "380px" } },
                [
                  _c("div", {
                    ref: "echarts6",
                    staticStyle: { height: "300px" },
                  }),
                  _c(
                    "el-form",
                    { attrs: { inline: true, model: _vm.searchStatistics6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "时间类型" },
                              on: { change: _vm.handleSearchStatistics6 },
                              model: {
                                value: _vm.searchStatistics6.timeLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchStatistics6,
                                    "timeLevel",
                                    $$v
                                  )
                                },
                                expression: "searchStatistics6.timeLevel",
                              },
                            },
                            _vm._l(
                              _vm.$_options["timeLevel_1"],
                              function (item) {
                                return _c("el-option", {
                                  key: item[1],
                                  attrs: { label: item[0], value: item[1] },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "年份" },
                              on: { change: _vm.handleSearchStatistics6 },
                              model: {
                                value: _vm.searchStatistics6.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics6, "year", $$v)
                                },
                                expression: "searchStatistics6.year",
                              },
                            },
                            _vm._l(
                              _vm.statistics6AvailableYears,
                              function (year) {
                                return _c("el-option", {
                                  key: year,
                                  attrs: { label: year, value: year },
                                })
                              }
                            ),
                            1
                          ),
                          _vm.searchStatistics6.timeLevel === "day"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "月份" },
                                  on: { change: _vm.handleSearchStatistics6 },
                                  model: {
                                    value: _vm.searchStatistics6.month,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics6,
                                        "month",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics6.month",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics6AvailableMonths,
                                  function (month) {
                                    return _c("el-option", {
                                      key: month,
                                      attrs: { label: month, value: month },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "20px", height: "380px" } },
                [
                  _c("div", {
                    ref: "echarts8",
                    staticStyle: { height: "300px" },
                  }),
                  _c(
                    "el-form",
                    { attrs: { inline: true, model: _vm.searchStatistics8 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "时间类型" },
                              on: { change: _vm.handleSearchStatistics8 },
                              model: {
                                value: _vm.searchStatistics8.timeLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchStatistics8,
                                    "timeLevel",
                                    $$v
                                  )
                                },
                                expression: "searchStatistics8.timeLevel",
                              },
                            },
                            _vm._l(
                              _vm.$_options["timeLevel_1"],
                              function (item) {
                                return _c("el-option", {
                                  key: item[1],
                                  attrs: { label: item[0], value: item[1] },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "年份" },
                              on: { change: _vm.handleSearchStatistics8 },
                              model: {
                                value: _vm.searchStatistics8.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics8, "year", $$v)
                                },
                                expression: "searchStatistics8.year",
                              },
                            },
                            _vm._l(
                              _vm.statistics8AvailableYears,
                              function (year) {
                                return _c("el-option", {
                                  key: year,
                                  attrs: { label: year, value: year },
                                })
                              }
                            ),
                            1
                          ),
                          _vm.searchStatistics8.timeLevel === "day"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "月份" },
                                  on: { change: _vm.handleSearchStatistics8 },
                                  model: {
                                    value: _vm.searchStatistics8.month,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics8,
                                        "month",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics8.month",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics8AvailableMonths,
                                  function (month) {
                                    return _c("el-option", {
                                      key: month,
                                      attrs: { label: month, value: month },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { "margin-top": "20px", "padding-right": "10px" },
              attrs: { span: 12 },
            },
            [
              _c(
                "el-card",
                { staticStyle: { height: "380px" } },
                [
                  _c("div", {
                    ref: "echarts5",
                    staticStyle: { height: "300px" },
                  }),
                  _c(
                    "el-form",
                    { attrs: { inline: true, model: _vm.searchStatistics5 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "时间类型" },
                              on: { change: _vm.handleSearchStatistics5 },
                              model: {
                                value: _vm.searchStatistics5.timeLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchStatistics5,
                                    "timeLevel",
                                    $$v
                                  )
                                },
                                expression: "searchStatistics5.timeLevel",
                              },
                            },
                            _vm._l(
                              _vm.$_options["timeLevel_3"],
                              function (item) {
                                return _c("el-option", {
                                  key: item[1],
                                  attrs: { label: item[0], value: item[1] },
                                })
                              }
                            ),
                            1
                          ),
                          _vm.searchStatistics5.timeLevel !== "hour"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "年份" },
                                  on: { change: _vm.handleSearchStatistics5 },
                                  model: {
                                    value: _vm.searchStatistics5.year,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics5,
                                        "year",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics5.year",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics5AvailableYears,
                                  function (year) {
                                    return _c("el-option", {
                                      key: year,
                                      attrs: { label: year, value: year },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm.searchStatistics5.timeLevel === "day"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "月份" },
                                  on: { change: _vm.handleSearchStatistics5 },
                                  model: {
                                    value: _vm.searchStatistics5.month,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics5,
                                        "month",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics5.month",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics5AvailableMonths,
                                  function (month) {
                                    return _c("el-option", {
                                      key: month,
                                      attrs: { label: month, value: month },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.searchStatistics5.timeLevel === "hour"
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "effectTime" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期时间",
                                  "picker-options":
                                    _vm.statistics5PickerOptions,
                                },
                                on: { change: _vm.handleSearchStatistics5 },
                                model: {
                                  value: _vm.searchStatistics5.hourDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchStatistics5,
                                      "hourDate",
                                      $$v
                                    )
                                  },
                                  expression: "searchStatistics5.hourDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                { staticStyle: { "margin-top": "20px", height: "380px" } },
                [
                  _c("div", {
                    ref: "echarts7",
                    staticStyle: { height: "300px" },
                  }),
                  _c(
                    "el-form",
                    { attrs: { inline: true, model: _vm.searchStatistics7 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "时间类型" },
                              on: { change: _vm.handleSearchStatistics7 },
                              model: {
                                value: _vm.searchStatistics7.timeLevel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchStatistics7,
                                    "timeLevel",
                                    $$v
                                  )
                                },
                                expression: "searchStatistics7.timeLevel",
                              },
                            },
                            _vm._l(
                              _vm.$_options["timeLevel_1"],
                              function (item) {
                                return _c("el-option", {
                                  key: item[1],
                                  attrs: { label: item[0], value: item[1] },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "年份" },
                              on: { change: _vm.handleSearchStatistics7 },
                              model: {
                                value: _vm.searchStatistics7.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchStatistics7, "year", $$v)
                                },
                                expression: "searchStatistics7.year",
                              },
                            },
                            _vm._l(
                              _vm.statistics7AvailableYears,
                              function (year) {
                                return _c("el-option", {
                                  key: year,
                                  attrs: { label: year, value: year },
                                })
                              }
                            ),
                            1
                          ),
                          _vm.searchStatistics7.timeLevel === "day"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "月份" },
                                  on: { change: _vm.handleSearchStatistics7 },
                                  model: {
                                    value: _vm.searchStatistics7.month,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchStatistics7,
                                        "month",
                                        $$v
                                      )
                                    },
                                    expression: "searchStatistics7.month",
                                  },
                                },
                                _vm._l(
                                  _vm.statistics7AvailableMonths,
                                  function (month) {
                                    return _c("el-option", {
                                      key: month,
                                      attrs: { label: month, value: month },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }