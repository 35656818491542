<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <h3>{{ isCollapse ? "后台" : "后台管理系统" }}</h3>
    <el-menu-item
      v-for="item in noChildren"
      @click="clickMenu(item)"
      :key="item.name"
      :index="item.name"
    >
      <!-- :index    等价于  v-bind:index   @click等价于v-on:click -->
      <i :class="`el-icon-${item.icon}`"></i>
      <!-- `  是tab键上面那个  -->
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu
      v-for="item in hasChildren"
      :key="item.label"
      :index="item.label"
    >
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <!-- `  是tab键上面那个  -->
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item
          v-for="subItem in item.children.filter(
            (i) => i.show === '1' || i.toShow === 1
          )"
          @click="clickMenu(subItem)"
          :key="subItem.name"
          :index="subItem.name"
          >{{ subItem.label }}
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      //   console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //   console.log(key, keyPath);
    },
    //点击菜单 进行跳转
    clickMenu(item) {
      //当页面的路由于要跳转的路由不一致的时候  才允许进行跳转
      //$route指的是当前页面的路由      #router指的是挂在到vue对象上的总实例
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
      this.$store.commit("selectMenu", item);
    },
  },
  computed: {
    //没有子菜单
    noChildren() {
      return this.menuData.filter(
        (item) => !item.children && (item.show === "1" || item.toShow === 1)
      );
    },
    hasChildren() {
      return this.menuData.filter(
        (item) => item.children && (item.show === "1" || item.toShow === 1)
      );
    },
    menuData() {
      let result = [];
      result =
        JSON.parse(localStorage.getItem("menu")) || this.$store.state.tab.menu;

      return result;
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100vh;
  border-right: none;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
