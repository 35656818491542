var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "el-menu-vertical-demo",
      attrs: {
        "default-active": "1-4-1",
        collapse: _vm.isCollapse,
        "background-color": "#545c64",
        "text-color": "#fff",
        "active-text-color": "#ffd04b",
      },
      on: { open: _vm.handleOpen, close: _vm.handleClose },
    },
    [
      _c("h3", [_vm._v(_vm._s(_vm.isCollapse ? "后台" : "后台管理系统"))]),
      _vm._l(_vm.noChildren, function (item) {
        return _c(
          "el-menu-item",
          {
            key: item.name,
            attrs: { index: item.name },
            on: {
              click: function ($event) {
                return _vm.clickMenu(item)
              },
            },
          },
          [
            _c("i", { class: `el-icon-${item.icon}` }),
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v(_vm._s(item.label)),
            ]),
          ]
        )
      }),
      _vm._l(_vm.hasChildren, function (item) {
        return _c(
          "el-submenu",
          { key: item.label, attrs: { index: item.label } },
          [
            _c("template", { slot: "title" }, [
              _c("i", { class: `el-icon-${item.icon}` }),
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(item.label)),
              ]),
            ]),
            _c(
              "el-menu-item-group",
              _vm._l(
                item.children.filter((i) => i.show === "1" || i.toShow === 1),
                function (subItem) {
                  return _c(
                    "el-menu-item",
                    {
                      key: subItem.name,
                      attrs: { index: subItem.name },
                      on: {
                        click: function ($event) {
                          return _vm.clickMenu(subItem)
                        },
                      },
                    },
                    [_vm._v(_vm._s(subItem.label) + " ")]
                  )
                }
              ),
              1
            ),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }