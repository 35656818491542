import Cookie from "js-cookie";

export default {
  //存放本地数据的地方
  state: {
    isCollapse: false, //用于控制菜单的展开和收起
    tabsList: [
      {
        path: "/",
        name: "home",
        label: "首页",
        icon: "s-home",
        url: "Home/Home",
      },
    ], //面包屑数据
    menu: [],
    pUserId: {},
    //权限
    permissions: [],
    //鼓机树状结构全局变量
    drumOptions: [],
    //拨片树状结构全局变量
    pickOptions: [],
    //曲谱分类选项全局变量
    songCategoryOptions: [],
    //曲谱标签树状结构全局变量
    songLabelOptions: [],
    musicalInstrumentOptions: [],
    clients: [],
  },
  mutations: {
    setClients(state, val) {
      state.clients = val;
    },
    setPUserId(state, val) {
      state.pUserId = val;
      localStorage.setItem("pUserId", val);
    },
    //修改菜单展开和收起的方法
    collapseMenu(state) {
      state.isCollapse = !state.isCollapse;
    },
    //更新面包屑数据
    selectMenu(state, val) {
      if (val.name !== "home") {
        const index = state.tabsList.findIndex(
          (item) => item.name === val.name
        );
        if (index === -1) {
          state.tabsList.push(val);
        }
      }
    },
    //设置鼓机全局变量
    setMusicalInstrumentOptions(state, val) {
      state.musicalInstrumentOptions = val;
    },
    //设置鼓机全局变量
    setDrumOptions(state, val) {
      state.drumOptions = val;
    },
    //设置拨片全局变量
    setPickOptions(state, val) {
      state.pickOptions = val;
    },
    //设置拨片全局变量
    setSongLabelOptions(state, val) {
      state.songLabelOptions = val;
    },
    //设置曲谱分类全局变量
    setSongCategoryOptions(state, val) {
      state.songCategoryOptions = val;
    },
    //删除指定的tag数据
    closeTag(state, item) {
      const index = state.tabsList.findIndex((val) => val.name === item.name);
      //数组中删除  第一个是要删除的位置  第二个参数是要删除的个数
      state.tabsList.splice(index, 1);
    },
    //设置mene的数据
    setMenu(state, val) {
      state.menu = val;
      //浏览器本地缓存
      localStorage.setItem("menu", JSON.stringify(val));
    },
    //设置权限版本
    setPermissions(state, val) {
      state.permissions = val;
      //浏览器本地缓存
      localStorage.setItem("permissions", JSON.stringify(val));
    },

    //动态注册路由   不同的账号菜单不一样  能路由的地方不一样
    addMenu(state, router) {
      if (!localStorage.getItem("menu")) {
        return;
      }
      const menu = JSON.parse(localStorage.getItem("menu"));
      state.menu = menu;
      //组装动态路由的数据
      const menuArray = [];
      menu.forEach((item) => {
        //有子菜单
        if (item.children) {
          item.children = item.children.map((item) => {
            item.component = () => import(`../views/${item.url}`);
            return item;
          });
          menuArray.push(...item.children);
        } else {
          //无子菜单
          item.component = () => import(`../views/${item.url}`);
          menuArray.push(item);
        }
        //路由的动态添加
        menuArray.forEach((item) => {
          router.addRoute("Main", item);
        });
      });
    },
  },
};
